import React from 'react'
import { Panel, RangeSlider } from 'rsuite'
import { useState } from 'react';


const FilterPrice = () => {

    const [PriceValue, setPriceValue] = useState([30, 40]);

    const handleSliderChange = (values) => {
        setPriceValue(values);
    };

  return (
    <Panel defaultExpanded header={<span className="fw-medium fs-5">Filter price</span>} collapsible>
                    <div>
                        <div className='my-4'>
                            <RangeSlider min={20} max={50} defaultValue={PriceValue} constraint={([start, end]) => start <= 40 && end >= 10} onChange={handleSliderChange} />
                        </div>
                        <div className='d-flex justify-content-around  align-items-center'>
                            <div className="border bg-white rounded-3 py-1 px-3">
                                <div>Min price</div>
                                <div className="text-dark">{PriceValue[0]} DZD</div>
                            </div>
                            <span className='mx-1'>-</span>
                            <div className="border bg-white rounded-3 py-1 px-3">
                                <div>Max price</div>
                                <div className="text-dark">{PriceValue[1]} DZD</div>
                            </div>
                        </div>
                    </div>
                </Panel>
  )
}

export default FilterPrice