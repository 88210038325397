import Plans from '../components/home/Plans'
import { useTranslation } from 'react-i18next';

const Pricing = () => {

  const { t } = useTranslation();


  return (
    <section>
      <div className="bg-grey p-5 text-center">
        <h1 className='text-dark fw-medium'>{t('pricing')}</h1>
        <span className="fw-light fs-6">{t('home')} - {t('pricing')}</span>
      </div>
      <div>
      <Plans/>
      </div>
    </section>
  )
}

export default Pricing