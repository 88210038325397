import TermsData from '../data/terms.json'
import { useTranslation } from 'react-i18next';

const Terms = () => {

    const { t } = useTranslation();

    return (
        <section>
            <div className="bg-grey p-5 text-center">
                <h1 className='fw-medium text-dark'>{t('terms_and_conditions')}</h1>
                <span className="fw-light fs-6">{t('home')} - {t('terms_and_conditions')}</span>
            </div>
            <div className='container'>
                {TermsData.terms.map((item,index)=>(
                    <div key={index} className='my-5'>
                        <div className='fw-medium fs-4 text-dark'>{item.title}</div>
                        <p className='mt-3 fw-light fs-6' dangerouslySetInnerHTML={{__html: item.content}}></p>
                    </div>
                ))}
            </div>
        </section>
    )
}

export default Terms