import { MapPin } from 'lucide-react'
import { Col, Row } from 'react-bootstrap'
import { Panel } from 'rsuite'

const Recap = ({ name, image, country, type, checkin, checkout, depart_time, return_time, travelers, duration, price }) => {
    return (
        <>
            <h3 className='text-dark'>Your Booking</h3>
            <div className='rounded-4 border mt-3 p-3 bg-white'>
                <Row className='align-items-center'>
                    <Col xs="4">
                        <img alt={`Illuatration - ${name}`} className='rounded-4' width="100%" height="100" src={image} />
                    </Col>
                    <Col xs="8">
                        <span className='fs-6 fw-bold text-dark'>{name}</span>
                        <div className='mt-3 d-flex align-items-center'>
                            <MapPin strokeWidth={1} size={15} />
                            <span className='mx-2'>{country}</span>
                        </div>
                    </Col>
                </Row>

                <div className='my-3 d-flex justify-content-between fs-6'>
                    <span>Type:</span>
                    <span>{type}</span>
                </div>

                <hr className='my-4 border-secondary' />

                <div className='my-3'>
                    <div className='d-flex justify-content-between fs-6 my-3'>
                        <span>
                            <div>Check In</div>
                            <div className='text-dark fw-bold fs-5'>{checkin}</div>
                            <div>{depart_time}</div>
                        </span>
                        <span>
                            <div>Check Out</div>
                            <div className='text-dark fw-bold fs-5'>{checkout}</div>
                            <div>{return_time}</div>
                        </span>
                    </div>
                    <div className='d-flex justify-content-between fs-6 my-3'>
                        <span>Travelers</span>
                        <span>{travelers}</span>
                    </div>
                    <div className='d-flex justify-content-between fs-6 my-3'>
                        <span>Duration</span>
                        <span>{duration}</span>
                    </div>
                </div>

                <hr className='my-4 border-secondary' />

                <div className='my-3'>
                    <Panel className='m-0 p-0' header={<span className='fw-bold fs-6 text-dark'>Coupon Code</span>} defaultExpanded collapsible>
                        <Row className='d-flex align-items-center justify-content-between my-3'>
                            <Col xs="8">
                                <input style={{ background: "#F7F8FA" }} className='input-blue p-3 px-5 rounded-5 border w-100' />
                            </Col>
                            <Col xs="4">
                                <button className='button-blue p-3 px-4 mx-1 rounded-5 w-100'>Apply</button>
                            </Col>

                        </Row>
                    </Panel>
                </div>

                <hr className='my-4 border-secondary' />

                <div className='my-3'>

                    <div className='d-flex justify-content-between fs-6 my-3'>
                        <span>Subtotal</span>
                        <span>${price}</span>
                    </div>

                    <hr className='my-2 border-secondary' />

                    <div className='d-flex justify-content-between fs-5 fw-bold text-dark my-3'>
                        <span>Pay Amount</span>
                        <span>${price}</span>
                    </div>


                </div>

            </div>
        </>
    )
}

export default Recap