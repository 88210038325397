import { Row, Col } from 'react-bootstrap';
import data from '../../data/flightdata.json'
import { Dot, Luggage, PlaneTakeoff } from 'lucide-react'
import Pagination from '../../components/Pagination';

const FlexDisplay = () => {

  const renderSpecificItems = (items) => {
    return (
      <>
        <Row>
          {items.map((flight, index) => (
            <Col key={index} xs="12" className='my-2'>
              <div className='bg-white rounded-4 border' style={{ overflow: "hidden" }}>
                <Row>

                  <Col md="9" className='border-end p-0'>

                    <div className='p-4 px-5'>
                      <div className='fs-6'><span className='fw-bold text-dark'>DEPARTURE</span><Dot /><span>{flight.departure_company}</span></div>
                      <Row className='my-3 align-items-center'>
                        <Col md="4" className='d-flex'>
                          <img src={flight.departure_company_img} className='rounded-circle border' alt={`Air company ${flight.departure_company}`} width={35} height={35} />
                          <div className='mx-3'>
                            <div className='fs-6 fw-bold text-dark'>{flight.departure_starttime}</div>
                            <div>{flight.airport_departing} - {flight.departure_location}</div>
                          </div>
                        </Col>
                        <Col md="4" className='d-flex align-items-center'>
                          <span>{flight.duration}</span><span className='mx-3 text-blue'><PlaneTakeoff size={20} strokeWidth={1.5} /></span><span>{flight.departure_state}</span>
                        </Col>
                        <Col md="4">
                          <div className='fs-6 fw-bold text-dark'>{flight.departure_endtime}</div>
                          <div>{flight.airport_arriving} - {flight.departure_destination}</div>
                        </Col>
                      </Row>
                      <div><Luggage size={15} /> {flight.luggage}</div>
                    </div>

                    <hr className='border-3 border-secondary w-100 m-0' />

                    <div className='p-4 px-5'>
                      <div className='fs-6'><span className='fw-bold text-dark'>RETURN</span><Dot /><span>{flight.return_company}</span></div>
                      <Row className='my-3 align-items-center'>
                        <Col md="4" className='d-flex'>
                          <img src={flight.return_company_img} className='rounded-circle border' alt={`Air company ${flight.return_company}`} width={35} height={35} />
                          <div className='mx-3'>
                            <div className='fs-6 fw-bold text-dark'>{flight.return_starttime}</div>
                            <div>{flight.airport_arriving} - {flight.return_location}</div>
                          </div>
                        </Col>
                        <Col md="4" className='d-flex align-items-center'>
                          <span>{flight.duration}</span><span className='mx-3 text-blue'><PlaneTakeoff size={20} strokeWidth={1.5} /></span><span>{flight.return_state}</span>
                        </Col>
                        <Col md="4">
                          <div className='fs-6 fw-bold text-dark'>{flight.return_endtime}</div>
                          <div>{flight.airport_departing} - {flight.return_destination}</div>
                        </Col>
                      </Row>
                      <div><Luggage size={15} /> {flight.luggage}</div>
                    </div>

                  </Col>

                  <Col md="3">
                    <div className='d-flex flex-column align-items-center justify-content-center h-100'>
                      <div>
                        From: <span className='fw-bold text-dark' style={{ fontSize: "16px" }}>€150.00</span>
                      </div>
                      <a href={`/flights/${flight.id}`} className='button-blue my-3 py-3 px-4 rounded-5 fw-bold'>View details</a>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          ))}
        </Row>
      </>
    );
  };

  return (
    <>
      <Pagination data={data.flights} itemsPerPage={9} renderItems={renderSpecificItems} />
    </>
  )
}

export default FlexDisplay