import { Check, Lightbulb } from "lucide-react";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Assistance = () => {
  const { t } = useTranslation();

  const support = [
    { title: t("assistance_title1"), description: t("assistance_description1") },

    { title: t("assistance_title2"), description: t("assistance_description2") },

    { title: t("assistance_title3"), description: t("assistance_description3"), icone: <Lightbulb color="#174CCB" size={50} /> },
  ];

  return (
    <Row className="my-5 py-3">
      <Col xs="12" xl="6" className="ps-xl-5 pe-xl-5 d-flex flex-column justify-content-around">
        <div className="text-dark fw-bold fs-2">{t("assistance")}</div>
        <p className="fs-5 fw-light">{t("assistance_intro")}</p>

        <div>
          {support.map((support, index) => (
            <Row className="mt-3 d-flex" key={index}>
              <Col sm="2" className="p-4 d-none d-sm-block">
                <Check color="#008A7C" size={30} />
              </Col>
              <Col xs="12" sm="10">
                <span className="text-dark fs-5 fw-medium">{support.title}</span>
                <p className="fs-5 fw-light mt-2" dangerouslySetInnerHTML={{ __html: support.description }}></p>
              </Col>
            </Row>
          ))}
        </div>
      </Col>
      <Col xs="12" xl="6" className="px-0">
        <img width="100%" height="100%" style={{ objectFit: "cover" }} src="https://images.pexels.com/photos/5467600/pexels-photo-5467600.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Assistance de voyage pour un client" loading="lazy" />
      </Col>
    </Row>
  );
};

export default Assistance;
