import { ChevronRight, Clock, Star, CheckCircle2, Info, MapPin, X } from 'lucide-react'
import { Row, Col } from 'react-bootstrap'
import { useParams } from "react-router-dom";
import details from '../../data/activitiesdata.json'
import React, { useState, useEffect } from 'react';
import GuestsCount from '../activities/forms/GuestsCount';
import { DateRangePicker } from 'rsuite';

const ActivityAdditions = ({ handleNextStep }) => {

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showModal]);


  const { id } = useParams();
  let activity = details.activities.filter(objet => objet.id === parseInt(id))[0];

  const [showActivityDetails, setShowActivityDetails] = useState(false);

  const toggleActivityDetails = () => {
    setShowActivityDetails(!showActivityDetails);
  };

  return (
    <div>
      <div className='fs-6 fw-bold'>
        <span className='text-decoration-underline'>1. Deals</span>
        <ChevronRight size={15} className='mx-2' />
        <span className='text-secondary'>2. Personal</span>
      </div>

      <div className="alert alert-danger text-center border-0 my-3">
        <Clock color="grey" size={15} className="mx2" /> We’ll hold your spot for 23:29 minutes.
      </div>

      <span className='fs-4'>Want to take advantage of these limited-time deals?</span>

      <div className='border p-4 rounded-3 my-3 fs-6 d-flex justify-content-between align-items-center'>
        <span>I don't want to add another activity</span>
        <button className='button-blue rounded-5 py-2 px-4 fw-bold' onClick={handleNextStep}>Skip this step</button>
      </div>

      <div>
        <div className='border rounded-3 p-3'>
          <Row>
            <Col xl="2">
              <div>
                <img className='rounded-3' width="100%" height="100%" alt={`Illustration activité - ${activity.name}`} src={activity.img} />
              </div>
            </Col>
            <Col xl="9">
              <div className='d-flex flex-column align-items-start'>

                <Row className='d-flex justify-content-between'>
                  <Col xl="8"><div className='fs-6 fw-bold'>{activity.name}</div></Col>
                  <Col xl="4"><span className='bg-secondary-subtle p-1 rounded'>10.9 km away</span></Col>
                </Row>
                <div className="d-flex align-items-center my-1">
                  <span>{activity.note}</span>
                  <span className="mx-2 d-flex">
                    {Array.from({ length: activity.note }).map((_, starIndex) => (
                      <div index={starIndex} className='me-1'><Star color='#F8D448' fill='#F8D448' size={13} /></div>
                    ))}
                  </span>
                  <span>({activity.reviews})</span>
                </div>
                <div className='bg-danger text-white p-1 rounded my-1'>5% off for a limited time*</div>

                <>
                  {showActivityDetails ?
                    <div className='w-100'>

                      <div className='border rounded my-3'>
                        <GuestsCount />
                      </div>

                      <div className="my-3 p-2 bg-white border rounded d-flex justify-content-center w-100">
                        <DateRangePicker />
                      </div>

                      <div className='mt-3'>Ticket option</div>
                      <div className="my-1 p-2 bg-white border rounded d-flex justify-content-center w-100" onClick={openModal}>
                        1- Ticket option - € 6.02
                      </div>

                      <div className="d-flex align-items-center my-2">
                        <CheckCircle2 color="green" />
                        <div className="mx-2">
                          {activity.cancel_text}
                        </div>
                      </div>
                      <div className='text-blue my-2'>
                        <a className='text-blue' href={`/activities/${activity.id}`}>View full details <Info size={15} /></a>
                      </div>

                    </div>
                    : ''
                  }
                </>
                <div className='my-2 d-flex align-items-center justify-content-between w-100' >
                  <div><span className='fs-5 text-danger'>€ 6.02</span> <span className='text-decoration-line-through'>€ 6.34</span></div>
                  <button className='button-blue p-3 px-4 rounded-5'>Add to order</button>
                </div>
              </div>

              <div>
                {
                  showModal && (
                    <div className="modal fs-6">
                      <Row className="w-100 h-100 d-flex justify-content-center align-items-center">
                        <Col xl="5">
                          <div className='bg-white text-dark p-3 rounded-4'>
                            <div className='d-flex align-items-center'>
                              <div className='text-primary' role='button' onClick={closeModal}><X /></div>
                              <div className='fs-5 fw-bold text-center w-100'>Select ticket option</div>
                            </div>
                            <div className='text-center my-3'>Showing total price for Adult x 1</div>
                            <div className='my-3'>
                              <Row>
                                <Col xs="1">
                                  <input type='radio' id='radio1' />
                                </Col>
                                <Col xs="9">
                                  <label htmlFor='radio1' className='ms-3'>
                                    <div className='fs-5'>1-Way Ticket</div>
                                    <Row className='my-3'>
                                      <Col xs="12" className='my-2'>
                                        <div className="d-flex">
                                          <Clock size={25} />
                                          <div className="mx-2">
                                            <span>{activity.duration}</span>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col xs="12" className='my-2'>
                                        <div className="d-flex">
                                          <MapPin size={25} />
                                          <div className="mx-2">
                                            <span>{activity.meeting_point}</span>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col xs="12" className='my-2'>
                                        <div>
                                          <div>
                                            Opening/operating hours:
                                          </div>
                                          <div className="my-2">
                                            8:00 AM - 11:00 PM
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </label>
                                </Col>
                                <Col xs="2">
                                  <div className='fs-5 text-danger'>€ {activity.price}</div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )
                }
              </div>

            </Col>
          </Row>
          <hr className="border-secondary m-0" />
          <div className='mt-2 d-flex justify-content-center'>
            {showActivityDetails ? <span role='button' className='fs-6 text-blue' onClick={toggleActivityDetails}>Show less</span> : <span role='button' className='fs-6 text-blue' onClick={toggleActivityDetails}>Show more</span>}
          </div>
        </div>
      </div>

    </div>
  )
}

export default ActivityAdditions