
const logos = [
    'intersky',
    'alaska',
    'finnair',
    'AeroSur',
    'jetairways',
    'airberlin',
    'aegean',
    'aircanada',
    'airfrance',
    'airberlin',
    'aegean',
    'americanairlines',
    'AeroSur',
    'alitalia',
    'argentina',
    'alaska',
    'finnair',
    'jetairways',
    'alitalia', 
    'biman', 
    'helvetic',
    'moldavian',
]

function Partenaires(){ 

    return(
        <div>
            <div className="marquee-container py-5">
                <div className="marquee-content">
                    {logos.map((logo,index)=>(
                        <img key={index} width={200} height={100} src={require(`../../assets/logos/${logo}.svg`)} alt={`Logo de - ${logo}`} /> 
                    ))}
                </div>
                <div className="marquee-content">
                    {logos.map((logo,index)=>(
                        <img key={index} width={200} height={100} src={require(`../../assets/logos/${logo}.svg`)} alt={`Logo de - ${logo}`} /> 
                    ))}
                </div>
            </div>
       </div>
    )
}

export default Partenaires