import { Row, Col } from 'react-bootstrap';
import logo from '../assets/404.svg'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

function ErrorPage() {

    const { t } = useTranslation();

    return (
        <section>
            <Row className='py-5'>
                <Col md="12">
                    <div className='d-flex justify-content-center align-items-center'>
                        <img style={{ maxWidth: '30%' }} src={logo} alt="logo du site" loading="lazy" />
                    </div>
                </Col>
                <Col md="12">
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <span className='h2 text-dark'>{t('oops')}</span>
                        <span className='fs-5'>{t('page_not_found')}</span>
                        <Link to='/' className='button-blue m-5 p-3 ps-4 pe-4 rounded-0 text-decoration-none'>{t('back_to_home')}</Link>
                    </div>
                </Col>
            </Row>
        </section>

    );
}

export default ErrorPage;