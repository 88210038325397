import Availability from './forms/Availability'
import Cars from '../../components/recommendations/Cars'

function Home() {



    return (
        <>
            <section className='bg-body-tertiary'>
                <div className='py-5 bg-blue-subtle border-bottom'>
                    <Availability />
                </div>

                <div className="container-xl py-5">
                    <>
                        <div className='text-dark fs-1 fw-medium text-center'>Our best sellers</div>
                        <p className="my-1 fs-6 text-center">Find your car</p>
                        <Cars/>
                    </>
                </div>
            </section>
        </>

    );

}

export default Home;