import { Row, Col } from 'react-bootstrap'
import { CalendarDays, HeartHandshake, Rocket, BookOpenCheck } from 'lucide-react'
import { useTranslation } from 'react-i18next';

const EventItems = () => {

    const { t } = useTranslation();

    const eventitems = [
        { title: t('eventitem_title1'), description: t('eventitem_description1'), icon: <HeartHandshake strokeWidth={1.2} size={50} /> },
        { title: t('eventitem_title2'), description: t('eventitem_description2'), icon: <CalendarDays strokeWidth={1.2} size={50} /> },
        { title: t('eventitem_title3'), description: t('eventitem_description3'), icon: <Rocket strokeWidth={1.2} size={50} /> },
        { title: t('eventitem_title4'), description: t('eventitem_description4'), icon: <BookOpenCheck strokeWidth={1.2} size={50} /> },
    ]

    return (
        <section className='d-none d-xl-block py-5'>
            <div className='container py-5 text-dark'>
                <span className='text-blue fw-light'>{t('our_mission')}</span>
                <h1 className='fw-bold'>{t('events_cat_title')}</h1>
                <p className='fs-6 my-4 fw-light'>{t('events_cat_intro')}</p>
                <Row>
                    {eventitems.map((event, index) => (
                        <Col key={index} lg="3" className='p-0'>
                            <div className='bg-blue-subtle p-4 py-5 text-dark border h-100'>
                                <div className='text-blue'>{event.icon}</div>
                                <div className='fs-4 fw-medium my-3'>{event.title} </div>
                                <p className='fw-light fs-6'>{event.description}</p>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </section>
    )
}

export default EventItems