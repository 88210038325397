import { Luggage } from 'lucide-react'
import { useParams } from "react-router-dom";
import details from '../../data/flightdata.json'
import { Row, Col } from 'react-bootstrap';
import { Panel } from 'rsuite';
import { MoveRight, ShoppingBasket, Check, BaggageClaim } from 'lucide-react'

function Details() {

    const { id } = useParams();
    let flight = details.flights.filter(objet => objet.id === parseInt(id))[0];

    return (
        <>
            <div className="container-xl p-3 my-3">
                <Row>
                    <Col lg="8" className='my-3'>
                        <div className='fw-bold text-dark'>
                            <div className='bg-white rounded-4 border-blue' style={{ overflow: "hidden" }}>
                                <div className='text-center text-white border-bottom bg-blue p-1 fs-6'>Please verify the information</div>

                                <div className='fs-4 border-bottom p-3'>Flight Details</div>

                                <div className='p-3'>
                                    <div className='d-flex align-items-center fs-5'>
                                        <span>{flight.departure_location} to {flight.departure_destination}</span>
                                    </div>

                                    <div className='fw-light fs-6 my-2'>Travel time: {flight.duration} {flight.departure_state}</div>

                                    <div className='text-success fw-medium fs-6 my-2'>{flight.date}</div>

                                    <div className='mt-3'>
                                        <div className='fs-5 my-1'>{flight.departure_starttime} - {flight.departure_endtime}</div>
                                        <div className='fw-light fs-5 my-1'>{flight.departure_location} - {flight.departure_destination}</div>
                                        <div className='fw-light fs-6 my-1'><span className='fw-bold'>Flight time:</span> - {flight.duration}</div>
                                    </div>
                                    <div className='mt-3'>
                                        <Row className='align-items-center'>
                                            <Col sm="6">
                                                <Row className='d-flex align-items-center w-100'>
                                                    <Col xs="6">
                                                        <img width="30" height="30" alt='Illustration de la copagnie aérienne du vol' src={flight.departure_company_img} />
                                                    </Col>
                                                    <Col xs="6">
                                                        <div className='mx-2'>
                                                            <div className='fs-5'>{flight.departure_company}</div>
                                                            <div className='fs-6 fw-light'>Flight: {flight.flightnumber}</div>
                                                        </div>
                                                    </Col>

                                                </Row>
                                            </Col>
                                            <Col sm="6">
                                                <div className='mx-2'>
                                                    <div className='fs-6'>Class: <span className='fw-light'>{flight.class}</span></div>
                                                    <div className='fs-6'>company: <span className='fw-light'>{flight.departure_company}</span></div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                            <div className='my-4 bg-white border p-3 rounded-4'>
                                <Row>
                                    <Col xl="2">
                                        <div className='text-blue'>
                                            <Luggage strokeWidth={1.5} size={100} />
                                        </div>
                                    </Col>
                                    <Col xl="10">
                                        <>
                                            <div className='fs-4'>Baggage Information (per person)</div>
                                            <div className='d-flex align-items-center my-1'>
                                                <span>{flight.departure_location}</span><span className='mx-2'><MoveRight /></span><span>{flight.departure_destination}</span>
                                            </div>
                                            <div className='my-1 fw-light fs-6'>
                                                {flight.departure_company} | {flight.class}
                                            </div>
                                            <Row className="my-3">
                                                <Col sm="6" lg="4">
                                                    <div className='d-flex'>
                                                        <div className='text-blue'>
                                                            <ShoppingBasket />
                                                        </div>
                                                        <div className='fw-light mx-2'>
                                                            <div className='fs-6'>Personal Item</div>
                                                            <div>Purse, small backpack, briefcase</div>
                                                            <div className='text-success my-1'><Check size={20} /> Included</div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm="6" lg="4">
                                                    <div className='d-flex'>
                                                        <div className='text-blue'>
                                                            <BaggageClaim />
                                                        </div>
                                                        <div className='fw-light mx-2'>
                                                            <div className='fs-6'>Carry-on bag</div>
                                                            <div>Fits in overhead bin or under the seat</div>
                                                            <div className='text-success my-1'><Check size={20} /> Included</div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg="4" className='my-3'>
                        <>
                            <div className='bg-white p-3 rounded-4 border'>
                                <Row className='align-items-center'>
                                    <Col xs="4">
                                        <img alt={`Illustration de la compagnie - ${flight.departure_company}`} className='rounded-4' width="50" height="50" src={flight.departure_company_img} />
                                    </Col>
                                    <Col xs="8">
                                        <span className='fs-6 fw-bold text-dark'>{flight.departure_location} - {flight.departure_destination}</span>
                                        <div>
                                            {flight.date}
                                        </div>
                                    </Col>
                                </Row>

                                <div className='my-3 d-flex justify-content-between fs-6'>
                                    <span>Flight type:</span>
                                    <span>{flight.state}</span>
                                </div>

                                <hr className='my-4 border-secondary' />

                                <div className='d-flex justify-content-between fs-6 my-3'>
                                    <span>Adults</span>
                                    <span>1</span>
                                </div>
                                <div className='d-flex justify-content-between fs-6 my-3'>
                                    <span>Childs</span>
                                    <span>0</span>
                                </div>
                                <div className='d-flex justify-content-between fs-6 my-3'>
                                    <span>Babys</span>
                                    <span>0</span>
                                </div>

                                <hr className='my-4 border-secondary' />

                                <div className='my-3'>
                                    <Panel className='m-0 p-0' header={<span className='fw-bold fs-6 text-dark'>Coupon Code</span>} defaultExpanded collapsible>
                                        <Row className='d-flex align-items-center justify-content-between my-3'>
                                            <Col xs="8">
                                                <input style={{ background: "#F7F8FA" }} className='input-blue p-3 px-5 rounded-5 border w-100' />
                                            </Col>
                                            <Col xs="4">
                                                <button className='button-blue p-3 px-4 mx-1 rounded-5 w-100'>Apply</button>
                                            </Col>

                                        </Row>
                                    </Panel>
                                </div>

                                <hr className='my-4 border-secondary' />

                                <div className='my-3'>

                                    <div className='d-flex justify-content-between fs-6 my-3'>
                                        <span>Subtotal</span>
                                        <span>$276,66</span>
                                    </div>

                                    <hr className='my-2 border-secondary' />

                                    <div className='d-flex justify-content-between fs-5 fw-bold text-dark my-3'>
                                        <span>Pay Amount</span>
                                        <span>$276,66</span>
                                    </div>

                                    <div className='my-3 d-flex'>
                                        <a href={`/booking/flight/${flight.id}`} className='button-blue p-3 rounded-5 w-100 text-center'>Reserve</a>
                                    </div>
                                </div>
                            </div>
                        </>
                    </Col>
                </Row>
            </div>
        </>

    );

}

export default Details;
