import { Panel } from 'rsuite';
import FilterPrice from '../../components/FilterPrice';

const Filters = () => {

    const compagny = [
        { name: "Turkish Airlines" },
        { name: "Air France" },
        { name: "Air algérie" },
        { name: "Royal air maroc" },
    ]

    const flighttype = [
        { name: "Direct" },
        { name: "01 escale" },
        { name: "02 escales" },
    ]

    const flightclass = [
        { name: "Economic" },
        { name: "First" },
        { name: "Business" }
    ]

    const flighttime = [
        { name: "05h00 - 11h49" },
        { name: "12h00 - 17h49" },
        { name: "18h00 - 23h59" },
    ]

    return (
        <>
            <div className="my-2 rounded-4 p-xl-3 bg-white border">
                <FilterPrice/>
            </div>

            <div className="my-2 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Category</span>} collapsible>
                    {compagny.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'><input type='checkbox' /><span className='my-1 mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-2 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Flight</span>} collapsible>
                    {flighttype.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'><input type='checkbox' /><span className='my-1 mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-2 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Class</span>} collapsible>
                    {flightclass.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'><input type='checkbox' /><span className='my-1 mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-2 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Depart time</span>} collapsible>
                    {flighttime.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'><input type='checkbox' /><span className='my-1 mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-2 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Arriving time</span>} collapsible>
                    {flighttime.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'><input type='checkbox' /><span className='my-1 mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

        </>
    )
}

export default Filters