import { Row, Col } from 'react-bootstrap';
import Partenaires from './Partenaires'
import { AvatarGroup, Avatar } from 'rsuite';
import Input from '../forms/Input'
import { useTranslation } from 'react-i18next';
import { useState } from 'react';


function Test() {

    const { t } = useTranslation();

    const [valeurSelect, setValeurSelect] = useState('');

    const handleChangeSelect = (event) => {
        const nouvelleValeur = event.target.value;
        setValeurSelect(nouvelleValeur);
    };

    const linkValues = [
        { option: t('what_are_you_looking_for'), value: "" },
        { option: t('flights'), value: "flights" },
        { option: t('hotels'), value: "hotels" },
        { option: t('activities'), value: "activities" },
        { option: t('cars'), value: "cars" },
        { option: t('transfers'), value: "transferts" }
    ]

    const users = [
        { avatar: 'https://avatars.githubusercontent.com/u/12592949', name: 'superman66' },
        { avatar: 'https://avatars.githubusercontent.com/u/1203827', name: 'simonguo' },
        { avatar: 'https://avatars.githubusercontent.com/u/9625224', name: 'theJian' },
        { avatar: 'https://avatars.githubusercontent.com/u/15884443', name: 'LeightonYao' },
        { avatar: 'https://avatars.githubusercontent.com/u/10924138', name: 'zmhawk' },
        { avatar: 'https://avatars.githubusercontent.com/u/2797600', name: 'posebear1990' },
        { avatar: 'https://avatars.githubusercontent.com/u/23637144', name: 'Sleaf' }
    ];

    const max = 2;


    return (
        <section style={{ position: "relative" }}>
            <div className="container py-5">
                <div className='pb-lg-5 mb-lg-5'>
                    <Row className='text-dark align-items-center'>
                        <Col lg="7" xl="6" className='background-text px-md-0'>
                            <div className="px-5 ps-4">
                                <h1 style={{ fontSize: "350%" }} className="fw-medium border-blue-start-3 px-4" dangerouslySetInnerHTML={{ __html: t('main_title') }}></h1>
                                <p className="mt-5 fw-light fs-6" dangerouslySetInnerHTML={{ __html: t('main_text') }}></p>
                            </div>
                        </Col>

                        <Col lg="5" xl="6" className='d-md-flex d-none'>
                            <>
                                <Row className="align-items-end justify-content-end position-relative">
                                    <Col md="6" lg="12" xl="6" className="my-3">
                                        <img className="rounded" width="100%" height="100%" src="https://consulting.stylemixthemes.com/kyiv/wp-content/uploads/sites/54/2022/09/man-sitting.png" alt='Manger coprpoare illustration' />
                                    </Col>
                                    <Col md="6" lg="6" xl="6" className="my-3 d-none d-md-block d-lg-none d-xl-block">
                                        <div className="position-absolute  d-flex justify-content-end" style={{ marginLeft: "-50px", top: "40px" }}>
                                            <div className='bg-white shadow-sm p-3 rounded-4 d-flex align-items-center'>
                                                <AvatarGroup stack>
                                                    {users
                                                        .filter((user, i) => i < max)
                                                        .map(user => (
                                                            <Avatar size='lg' circle key={user.name} src={user.avatar} alt={user.name} />
                                                        ))}
                                                    <Avatar size='lg' circle style={{ background: '#008A7C' }}>
                                                        +{users.length - max}
                                                    </Avatar>
                                                </AvatarGroup>
                                                <div className='mx-2'>
                                                    <div className='fs-6 fw-bold'>4.8 {t('rated')}</div>
                                                    <p className='fw-light fs-6'>{t('around_the_world')}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <img className="rounded" width="100%" height="100%" src="https://radiustheme.com/demo/wordpress/themes/finbuzz/wp-content/uploads/2021/10/blog4-570x447.jpg" alt='Team work illustration' />
                                    </Col>
                                </Row>
                            </>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl="8">
                            <Row className='fw-light fs-6'>
                                <Col xl="9">
                                    <Row className='align-items-center'>
                                        <Col md="5" className='my-2'>
                                            <select value={valeurSelect} onChange={handleChangeSelect} className='border px-2 p-4 w-100 fw-light'>

                                                {linkValues.map((option, index) => (
                                                    <option key={index} value={option.value}>{option.option}</option>
                                                ))}
                                            </select>
                                        </Col>
                                        <Col md="4" className='my-2'>
                                            <Input placeholder={t('location')} />
                                        </Col>
                                        <Col md="3" className='my-2'>
                                            <div className="p-4 px-5 button-orange d-flex justify-content-center">
                                                <a className='text-dark' href={`/${valeurSelect}`}>{t('search')}</a>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="fw-light">
                                        <span className="text-blue fw-medium">{t('popular_search')} :</span> New-York, Paris, Madrid, Dubaï ...
                                    </div>
                                </Col>
                            </Row>
                            <Partenaires />
                        </Col>
                        <Col xl="4" className='d-none d-xl-block'>
                            <img className="rounded" width="100%" height="100%" src="https://jusdial.wpengine.com/wp-content/uploads/2023/04/Home3-slider-image-3-768x448.webp" alt='Deal signed illustration' />

                        </Col>
                    </Row>
                </div>
            </div>
            <div className="waves d-none d-lg-block">
                <div className="wave wave1"></div>
                <div className="wave wave2"></div>
                <div className="wave wave3"></div>
                <div className="wave wave4"></div>
            </div>
        </section>
    )
}

export default Test