import React, { useState } from 'react';
import { Row, Col, Offcanvas } from 'react-bootstrap';
import Filters from './Filters'
import GridDisplay from './GridDisplay'
import FlexDisplay from './FlexDisplay'
import { SlidersHorizontal } from 'lucide-react'
import { LayoutGrid, StretchHorizontal } from 'lucide-react'
import Availability from './forms/Availability'
import TransfertItem from '../../data/transfersdata.json'

function Search() {

    const [ActiveDisplay, setActiveDisplay] = useState('flex');

    const handleClick = (bouton) => {
        setActiveDisplay(bouton);
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <section className='bg-body-tertiary'>
                <div className='py-5 bg-blue-subtle border-bottom'>
                    <Availability />
                </div>

                <div className="container-xl py-5">
                    <Offcanvas className="w-100" show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Filters />
                        </Offcanvas.Body>
                    </Offcanvas>
                    <Row>
                        <Col className='d-none d-xl-block' xl="3">
                            <Filters />
                        </Col>
                        <Col xl="9">
                            <div className='d-flex justify-content-between align-items-center bg-white border p-3 rounded-4'>
                                <span onClick={handleShow} className='d-xl-none btn btn-light border rounded-5 d-flex align-items-center'><SlidersHorizontal className='me-1' strokeWidth={1} size={15} />
                                    Filters
                                </span>

                                <span style={{ fontSize: "16px" }} className='fw-light d-none d-xl-block fs-6 text-blue'> {TransfertItem.transfers.length} tranferts found</span>

                                <div className='d-lg-inline d-none'>
                                    <span role='button'  title="List view"><StretchHorizontal onClick={() => handleClick('flex')} className={`mx-1 ${ActiveDisplay === 'flex' ? 'text-blue' : ''}`} size={20} strokeWidth={1}  /> </span>
                                    <span role='button'  title="Grid view"><LayoutGrid onClick={() => handleClick('grid')} className={`mx-1 ${ActiveDisplay === 'grid' ? 'text-blue' : ''}`} size={20} strokeWidth={1} /></span>
                                </div>
                            </div>
                            <div>
                                {ActiveDisplay === 'flex' && <FlexDisplay />}
                                {ActiveDisplay === 'grid' && <GridDisplay />}
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
        </>

    );

}

export default Search;