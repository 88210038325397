import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import UserList from '../../data/userlist.json'
import i18n from '../../lang/i18n';
import { Dot, MapPin } from 'lucide-react';

const Reviews = () => {

    const { t } = useTranslation();

    const [usersData, setRandomData] = useState([]);
    const currentLanguage = i18n.language;

    useEffect(() => {

        const shuffledData = [...UserList[currentLanguage]].sort(() => Math.random() - 0.5);

        const slicedData = shuffledData.slice(0, 1);
        setRandomData(slicedData);

    }, []);

    return (
        <section className='container'>

            <div className='mt-5 text-dark'>
                <div className='fs-2 fw-medium text-center'>{t('reviews_title')}</div>
                <p className='text-center fw-light fs-5'>{t('reviews_text')}</p>

                <Row className='align-items-center'>
                    <Col lg="1">
                        <img src='https://randomuser.me/api/portraits/men/91.jpg' width={59} height={59} alt='random user 1' className='rounded-circle' />
                    </Col>
                    <Col lg="10">
                        <div className='d-flex p-5 py-3'>
                            {usersData.map((user, index) => (
                                <div key={index} className='px-3 d-flex flex-column justify-content-between align-items-center h-100'>
                                    <div className='w-100 d-flex justify-content-between align-items-center my-3'>
                                        <img src='https://randomuser.me/api/portraits/women/11.jpg' width={59} height={59} alt='random user 1' className='rounded-circle' />
                                        <img src={user.picture} width={126} height={120} alt='random user 1' className='rounded-circle border-blue' />
                                        <img src='https://randomuser.me/api/portraits/men/85.jpg' width={59} height={59} alt='random user 1' className='rounded-circle' />
                                    </div>

                                    <div className='d-flex mt-5'>
                                        <img src='https://chisfis-nextjs.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fquotation.d3711ae3.png&w=64&q=75' width={52} height={45} alt='random user 1' />

                                        <p className='text-center fw-light fs-4 px-3'>{user.review}</p>

                                        <img src='https://chisfis-nextjs.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fquotation.d3711ae3.png&w=64&q=75' width={52} height={45} alt='random user 1' />
                                    </div>

                                    <>
                                        <div className='my-3 text-center'>
                                            <div className='fs-4 fw-medium'>{user.name}</div>
                                            <div className='fw-light fs-6 mt-1'><MapPin size={20} strokeWidth={1} /> {user.country}</div>
                                        </div>
                                    </>
                                    <div className='d-flex my-4'>
                                        <img className='mx-5 mt-4 rounded-circle' src='https://randomuser.me/api/portraits/women/3.jpg' width={59} height={59} alt='random user 1' />
                                        <span className='mx-5'><Dot /><Dot /><Dot /></span>
                                        <img className='mx-5 rounded-circle' src='https://randomuser.me/api/portraits/men/11.jpg' width={59} height={59} alt='random user 1' />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Col>
                    <Col lg="1">
                        <img src='https://randomuser.me/api/portraits/women/34.jpg' width={59} height={59} alt='random user 1' className='rounded-circle' />
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Reviews