import Availability from './forms/Availability'
import CapitalDestination from '../../components/CapitalDestination'
import TopDestination from '../../components/home/TopDestinations'

const Home = () => {
    return (
        <section className='bg-body-tertiary'>
            <div className='py-5 bg-blue-subtle border-bottom'>
                <Availability />
            </div>
            <div className="container py-5">
                <TopDestination />
                <CapitalDestination />
            </div>
        </section>
    )
}

export default Home