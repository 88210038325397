import { Row, Col } from 'react-bootstrap'
import { Mail, MapPin, Smartphone } from 'lucide-react'
import FormContact from './FormContact'
import company from '../../data/company.json'
import { useTranslation } from 'react-i18next';

const Contact = () => {

    const { t } = useTranslation();

    return (
        <div className='container py-5'>
            <Row>
                <Col xl="6">
                    <div className='p-sm-5'>
                        <span className='fw-bold text-dark fs-3'>{t('leave_message')}</span>
                        <div className='my-4'>
                            <span className='text-blue'><MapPin size={40} strokeWidth={1.2} /></span>
                            <div className='fw-medium fs-5 my-2 text-dark'>{t('address')}</div>
                            <div className='fw-light'>{company.adress}</div>
                        </div>

                        <div className='my-4'>
                            <span className='text-blue'> <Smartphone size={40} strokeWidth={1.2} /></span>
                            <div className='fw-medium fs-5 my-2 text-dark'>{t('call_us')}</div>
                            <div className='fw-light'>{company.phone}</div>
                        </div>

                        <div className='my-4'>
                            <span className='text-blue'><Mail size={40} strokeWidth={1.2} /></span>
                            <div className='fw-medium fs-5 my-2 text-dark'>{t('email')}</div>
                            <div className='fw-light'>{company.contact}</div>
                        </div>

                    </div>
                </Col>
                <Col xl="6">
                    <FormContact />
                </Col>
            </Row>
        </div>
    )
}

export default Contact