import { Row, Col } from 'react-bootstrap'
import { Check, X } from 'lucide-react'
import { useTranslation } from 'react-i18next';

const Plans = () => {

    const { t } = useTranslation();

    const pricing = [
        { type: t('basic'), price: t('free'), included: [t('limited_reservations'), t('basic_costumer_support')], not_included: [t('entire_catalog'), t('free_cancellation'), t('all_features'), t('special_discounts_and_benefits')], image: "https://modernize-angular-main.netlify.app/assets/images/backgrounds/silver.png" },
        { type: t('entreprise'), price: "250", included: [t('unlimited_reservations'), t('entire_catalog'), t('priority_customer_support'), t('free_cancellation'), t('all_features'), t('special_discounts_and_benefits')], not_included: [], image: "https://modernize-angular-main.netlify.app/assets/images/backgrounds/gold.png" },
        { type: t('premium'), price: "85", included: [t('unlimited_reservations'), t('entire_catalog'), t('advanced_customer_support')], not_included: [t('free_cancellation'), t('all_features'), t('special_discounts_and_benefits')], image: "https://modernize-angular-main.netlify.app/assets/images/backgrounds/bronze.png" }
    ]

    return (
        <section className='py-lg-5 pt-3 px-1 px-md-0'>

            <div className='mt-3'>
                <h1 className='text-dark text-center fw-medium fs-2'>{t('pricing_title')}</h1>
                <p className='text-center fw-light fs-6'>{t('pricing_text')}</p>
            </div>
            <div className='container-lg mt-5'>
                <Row className='justify-content-center align-items-center text-dark fs-6'>
                    {pricing.map((plan, index) => (
                        <Col md="12" xl="4" className='px-md-4 pricing-component my-3' key={index}>
                            <Row className='pricing-item rounded-4 p-4 py-5'>
                                <Col md="4" xl="12">
                                    <>
                                        <div className='fw-light'>{plan.type}</div>
                                        <img className='my-3' width={80} height={80} src={plan.image} alt='Prcing plan illustration trophy' />
                                        <div className='fw-light'>
                                            $<span className='fs-1 fw-medium'>{plan.price}</span> /mo
                                        </div>

                                    </>
                                </Col>

                                <Col md="4" xl="12">
                                    <div className='px-2 my-4'>
                                        {
                                            plan.included.map((service, index) => (
                                                <div key={index} className='my-3 fw-light'>
                                                    <span className='text-blue'><Check size={15} /></span> <span className='mx-2'>{service}</span>
                                                </div>
                                            ))
                                        }
                                        {
                                            plan.not_included.map((service, index) => (
                                                <div key={index} className='my-3 fw-light'>
                                                    <span className='text-danger'><X size={15} /></span> <span className='mx-2'>{service}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Col>

                                <Col md="4" xl="12">
                                    <div className='d-flex justify-content-center'>
                                        <button className='btn p-3 px-5 w-75'>{t('try')}</button>
                                    </div>
                                </Col>

                            </Row>
                        </Col>
                    ))}
                </Row>
            </div>
        </section>
    )
}

export default Plans