import React, { useEffect } from 'react';

const TawkToChat = () => {
  useEffect(() => {
    // Insérez ici le code d'intégration fourni par Tawk.to
    var Tawk_API = Tawk_API || {};
    Tawk_API.onLoad = function () {
      // Vous pouvez ajouter des actions à exécuter lorsque le chat est chargé
    };

    // Code d'intégration Tawk.to
    var s1 = document.createElement("script");
    var s0 = document.getElementsByTagName("script")[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/6502c7560f2b18434fd8765b/1ha9dlar7';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  }, []);

  return (
    <div>
      {/* Votre contenu React */}
    </div>
  );
};

export default TawkToChat;
