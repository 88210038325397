import React, { useState } from 'react';
import Hotels from './Hotels'
import Activities from './Activities'
import Cars from './Cars'
import Rental from './Rental'
import Packages from './Packages'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

const Recommendations = () => {
    
    const [boutonActif, setBoutonActif] = useState('hotel');
    const { t } = useTranslation();

    const handleClick = (bouton) => {
        setBoutonActif(bouton);
    };

    const buttons = [
        { title: t('hotel'), value: "hotel" },
        { title: t('package'), value: "package" },
        { title: t('activity'), value: "activity" },
        { title: t('rental'), value: "rental" },
        { title: t('car'), value: "car" }
      ]

    return (
        <div style={{ background: "#F7F8FA" }}>
            <section className='container py-5 my-5'>
                <h1 className='text-dark text-center fs-1 mt-3 fw-medium'>{t('recommended_for_you')}</h1>
                <Row className='align-items-center justify-content-center mt-4'>
                    {buttons.map((button, index) => (
                        <Col key={index} xs="3" md="2" xl='2' className='justify-content-center my-1'>
                            <button className={`w-100 border rounded-5 p-2 px-3 fw-medium text-center ${boutonActif === button.value ? 'bg-blue text-white' : 'bg-white'}`} onClick={() => handleClick(button.value)}>
                            {button.title}
                            </button>
                        </Col>
                    ))}
                </Row>

                <>
                    {boutonActif === 'hotel' && <Hotels />}
                    {boutonActif === 'package' && <Packages />}
                    {boutonActif === 'activity' && <Activities />}
                    {boutonActif === 'rental' && <Rental />}
                    {boutonActif === 'car' && <Cars />}
                </>

            </section>
        </div>
    )
}

export default Recommendations