import Planification from './Planification';
import Tarification from './Tarification';
import Securite from './Securite';
import Assistance from './Assistance';

const SolutionsItems = () => {
    
    return (


        <section className='container'>
            <Planification />
            <Tarification />
            <Securite />
            <Assistance />
        </section>
    )
}

export default SolutionsItems