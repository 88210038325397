import { Col, Row } from "react-bootstrap"
import Reviews from '../components/home/Reviews'
import Partenaires from '../components/home/Partenaires'
import { useTranslation } from 'react-i18next';

const About = () => {

  const { t } = useTranslation();

  const numbers = [
    { title: "100+", notation: t('monthly_active_travelers') },
    { title: "500k", notation: t('travels_around_the_world') },
    { title: "17+", notation: t('field_experience') },
  ]

  return (

    <section>
      <div className="bg-grey p-5 text-center">
        <h1 className="fw-medium text-dark">{t('about_us')}</h1>
        <span className="fw-light fs-6">{t('home')} - {t('about_us')}</span>
      </div>
      <div className="container">
        <Row className="my-5">
          <Col md="3" className="my-2">
            <img className="rounded" src="https://superio-appdir.vercel.app/_next/image?url=%2Fimages%2Fresource%2Fabout-img-1.jpg&w=384&q=75" width="100%" height="100%" alt="company illustration 1" />
          </Col>
          <Col md="6">
            <Row>
              <Col md="6" className="my-2">
                <img className="rounded" src="https://superio-appdir.vercel.app/_next/image?url=%2Fimages%2Fresource%2Fabout-img-2.jpg&w=384&q=75" width="100%" height="100%" alt="company illustration 1" />
              </Col>
              <Col md="6" className="my-2">
                <img className="rounded" src="https://superio-appdir.vercel.app/_next/image?url=%2Fimages%2Fresource%2Fabout-img-4.jpg&w=384&q=75" width="100%" height="100%" alt="company illustration 1" />
              </Col>
              <Col md="6" className="my-2">
                <img className="rounded" src="https://superio-appdir.vercel.app/_next/image?url=%2Fimages%2Fresource%2Fabout-img-3.jpg&w=384&q=75" width="100%" height="100%" alt="company illustration 1" />
              </Col>
              <Col md="6" className="my-2">
                <img className="rounded" src="https://superio-appdir.vercel.app/_next/image?url=%2Fimages%2Fresource%2Fabout-img-5.jpg&w=384&q=75" width="100%" height="100%" alt="company illustration 1" />
              </Col>
            </Row>
          </Col>
          <Col md="3" className="my-2">
            <img className="rounded" src="https://superio-appdir.vercel.app/_next/image?url=%2Fimages%2Fresource%2Fabout-img-6.jpg&w=384&q=75" width="100%" height="100%" alt="company illustration 6" />
          </Col>
        </Row>

        <Row className="justify-content-center my-3">
          {numbers.map((number, index) => (
            <Col key={index} sm="3" className="text-center my-3">
              <div className="fs-1 fw-bold text-blue">{number.title}</div>
              <p className="fw-light fs-6">{number.notation}</p>
            </Col>
          ))}
        </Row>

        <>
          <div className="fs-3 fw-medium mt-5 text-dark">{t('about')} Idhcorp</div>
          <p className="my-3 fw-light fs-6 text-dark" dangerouslySetInnerHTML={{__html: t('aboutus_text')}}></p>
        </>
        <Reviews />
        <Partenaires />
      </div>
    </section>
  )
}

export default About