import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Reasons() {

   const { t } = useTranslation();

   const reasons = [
      { title: t('competetive_prices'), img: "1.svg" },
      { title: t('smooth_booking'), img: "2.svg" },
      { title: t('rich_catalog'), img: "3.svg" },
      { title: t('support24'), img: "4.svg" }
   ]

   return (
      <section className='container d-none d-sm-block'>

         <Row className='justify-content-between py-5'>
            {reasons.map((reason, index) => (
               <Col sm="6" md="3" key={index}>
                  <div className='d-flex flex-column align-items-center p-3'>
                     <img width={70} height={70} src={require(`../../assets/icones/${reason.img}`)} alt={`Illustration pour -${reason.title}`} />
                     <div className='fw-light fs-5 mt-3 text-dark text-center'>{reason.title}</div>
                  </div>
               </Col>
            ))}
         </Row>
      </section>
   );
}

export default Reasons;