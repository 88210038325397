import { AppWindow, Brush, Coins, Cross, Medal, Megaphone, Rocket, School, Utensils } from "lucide-react"
import { Col, Row } from "react-bootstrap"
import { useTranslation } from 'react-i18next';

const EventsCategory = () => {

    const { t } = useTranslation();

    const elements = [
        { title: t('accounting'), content:2, icon: <Coins size={40} strokeWidth={0.8} /> },
        { title: t('marketing'), content:13, icon: <Megaphone size={40} strokeWidth={0.8} /> },
        { title: t('design'), content:6, icon: <Brush size={40} strokeWidth={0.8} /> },
        { title: t('development'), content:4, icon: <AppWindow size={40} strokeWidth={0.8} /> },
        { title: t('education'), content:11, icon: <School size={40} strokeWidth={0.8} /> },
        { title: t('health_and_care'), content:7, icon: <Cross size={40} strokeWidth={0.8} /> },
        { title: t('automotive_events'), content:20, icon: <Rocket size={40} strokeWidth={0.8} /> },
        { title: t('sports'), content:9, icon: <Medal size={40} strokeWidth={0.8} /> },
        { title: t('gastronomic_events'), content:9, icon: <Utensils size={40} strokeWidth={0.8} /> },
    ]

    

    return (
        <section className='py-lg-5 px-1 px-md-0'>
            <div className='mt-3'>
                <h1 className='text-dark text-center fw-medium fs-2'>{t('popular_events')}</h1>
                <p className='text-center fw-light fs-6'>23 {t('added_this_month')}.</p>
            </div>
            <div className="mt-5 container">
                <Row>
                    {elements.map((item,index)=>(
                        <Col key={index} sm="6" lg="4" className="my-3">
                            <div className="d-flex border rounded-4 p-3">
                                <span className="bg-grey text-blue p-3 rounded-4 event-icon">{item.icon}</span>
                                <span className="mx-3">
                                    <div className="text-dark fw-medium fs-6">{item.title}</div>
                                    <div className="fw-light">({item.content} {t('open_events')})</div>
                                </span>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </section>
    )
}

export default EventsCategory