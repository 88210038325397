import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink } from 'react-router-dom';
import { ChevronRight } from 'lucide-react'

const Aside = ({ show, handleClose }) => {



    return (
        <aside>
            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div onClick={handleClose}>
                        <nav>
                            <ul className='left-menu-list list-unstyled fs-5 fw-bold'>
                                <li className='p-3 border-bottom d-flex justify-content-between align-items-center '><NavLink to="/">HOME</NavLink><ChevronRight strokeWidth={1.5} size={15} /></li>
                                <li className='p-3 border-bottom d-flex justify-content-between align-items-center'><NavLink to="/solutions">SOLUTIONS</NavLink><ChevronRight strokeWidth={1.5} size={15} /></li>
                                <li className='p-3 border-bottom d-flex justify-content-between align-items-center'><NavLink to="/events">EVENTS</NavLink><ChevronRight strokeWidth={1.5} size={15} /></li>
                                <li className='p-3 border-bottom d-flex justify-content-between align-items-center'><NavLink to="/contact">CONTACT</NavLink><ChevronRight strokeWidth={1.5} size={15} /></li>
                                <li className='p-3 border-bottom d-flex justify-content-between align-items-center'><NavLink to="/pricing">PRICING</NavLink><ChevronRight strokeWidth={1.5} size={15} /></li>
                                <li className='p-3 border-bottom d-flex justify-content-between align-items-center'><NavLink to="/about">ABOUT</NavLink><ChevronRight strokeWidth={1.5} size={15} /></li>
                                <li className='p-3 border-bottom d-flex justify-content-between align-items-center'><NavLink to="/terms">Terms</NavLink><ChevronRight strokeWidth={1.5} size={15} /></li>
                                <li className='p-3 border-bottom d-flex justify-content-between align-items-center'><NavLink to="/faq">FAQ</NavLink><ChevronRight strokeWidth={1.5} size={15} /></li>
                            </ul>
                        </nav>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </aside>
    )
}

export default Aside