import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Check } from 'lucide-react'
import { useTranslation } from 'react-i18next';

const Steps = () => {

    const { t } = useTranslation();

    const steps = [
        { title: t('step_title1'), description: t('step_description1'), step: 1 },
        { title: t('step_title2'), description: t('step_description2'), step: 2 },
        { title: t('step_title3'), description: t('step_description3'), step: 3 }
    ]

    return (
        <section>
            <div className='container'>
                <Row className='justify-content-between align-items-center'>
                    <Col xl="6">
                        <div className='p-3 text-dark fs-6'>
                            <span className='text-blue'>{t('travel_excellence')}</span>
                            <h2 className='fw-medium h1 mt-3'>{t('steps_title')}</h2>
                            <p className='my-5' dangerouslySetInnerHTML={{__html: t('steps_text')}}></p>
                            <div>
                                <div><Check className='me-3' color='#0555DA' size={15} /> {t('step_features1')}</div>
                                <div className='my-3'><Check className='me-3' color='#0555DA' size={15} /> {t('step_features2')}</div>
                                <div><Check className='me-3' color='#0555DA' size={15} /> {t('step_features3')}</div>
                            </div>
                        </div>
                    </Col>
                    <Col xl="5">
                        <div className='d-flex justify-content-center p-3'>
                            <Row>
                                <Col xs="3">
                                    <div className='d-flex flex-column align-items-center justify-content-around py-5 fs-5'>
                                        <div className='d-flex align-items-center'>
                                            <div className='step p-3'>01</div>
                                        </div>
                                        <img width={1} height={150} src='https://wpriverthemes.com/HTML/synck/assets/imgs/line-shape-4.png' alt='timeline' />
                                        <div className='d-flex align-items-center'>
                                            <div className='step p-3'>02</div>
                                        </div>
                                        <img width={1} height={150} src='https://wpriverthemes.com/HTML/synck/assets/imgs/line-shape-4.png' alt='timeline' />
                                        <div className='d-flex align-items-center'>
                                            <div className='step p-3'>03</div>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs="9">
                                    <div className='d-flex flex-column justify-content-between h-100'>
                                        {steps.map((step, index) => (
                                            <div key={index} className='bg-blue-subtle p-4 rounded-4 border'>
                                                <span className='fs-5 fw-medium text-dark'>{step.title}</span>
                                                <p className='fw-light mt-2 fs-6'>{step.description}</p>
                                            </div>
                                        ))

                                        }
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Steps