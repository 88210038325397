import { MoveRight, Tag } from "lucide-react"
import { Col, Row } from "react-bootstrap"
import { useTranslation } from 'react-i18next';

const EventList = () => {

  const { t } = useTranslation();

  const eventslist = [
    { title: "West Elm Exhibz light behold likeness midst Meat", introduction: "There’s such a thing as “too much information”, especially for the companies scaling out their sales operations. That’s why Attentive was born in 2015 help sales teams make their increasing pipelines simpler to manage. Indeed, the small, Portugal-based team is…", date: "JANUARY 1, 2019", type: "Education",location:"France",image:"https://demo-themewinter.com/exhibz/education/wp-content/uploads/sites/4/2019/01/blog2-750x465.jpg" },

    { title: "New Digital Man beast one won’t may kind rule", introduction: "There’s such a thing as “too much information”, especially for the companies scaling out their sales operations. That’s why Attentive was born in 2015 help sales teams make their increasing pipelines simpler to manage. Indeed, the small, Portugal-based team is…", date: "JANUARY 1, 2019", type: "Degital",location:"France",image:"https://demo-themewinter.com/exhibz/education/wp-content/uploads/sites/4/2019/01/blog1-750x465.jpg" },
  ]




  return (
    <section className='container'>
      <span className='fs-6 text-blue fw-light'>{t('our_events')}</span>
      <h1 className='text-dark fw-bold'>{t('upcoming_events')}</h1>
      <p className='fs-6 my-3 fw-light'>{t('eventslist_intro')}</p>

      <div className="my-5">
        {eventslist.map((event, index) => (
          <article>
            <Row className='my-5 text-dark' key={index}>
              <Col lg='1' className='d-none d-lg-block'>
                <div className='d-flex flex-column align-items-center'>
                  <span>Sat</span>
                  <span className='fs-1 text-blue fw-bold'>Jan</span>
                  <span>2024</span>
                </div>
              </Col>

              <Col lg='7'>
                <div className='px-3 d-flex flex-column justify-content-between'>
                  <div className='fs-3 fw-light'>{event.location}</div>
                  <div className='h2'>{event.title}</div>
                  <p className="my-3 fs-5 fw-light">{event.introduction}</p>
                  <div className="my-3"><Tag size={20}/> {event.type}</div>
                </div>
              </Col>

              <Col lg='4'>
                <div className='d-flex flex-column'>
                  <img width={415} height={233} className='w-100 h-100' alt={`Evénement - ${event.title}`} src={event.image} />
                  <a href={event.link} title={`Voir les détails de l'événement sur ${event.sourceName}`} className='button-orange rounded-0 pe-5 ps-5 p-4 fs-6'>{t('read_more')} <MoveRight className="mx-2"/></a>
                </div>
              </Col>
            </Row>
          </article>
        ))}
      </div>
    </section>
  )
}

export default EventList