import { Menu, User, ShoppingCart } from 'lucide-react';
import logo from '../assets/main-logo.svg'
import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import NavBar from './NavBar';
import LeftMenu from './Aside'
import LoginLModal from '../components/LoginLModal'
import { Dropdown } from 'rsuite';
import { useTranslation } from 'react-i18next';
import { changeLanguage } from '../lang/i18n';
import i18n from '../lang/i18n';
import { useLocation } from 'react-router-dom';


function Header() {

    const currentLanguage = i18n.language;
    const { t } = useTranslation();
    const location = useLocation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showModal, setShowModal] = useState(false);
    const handleModalClose = () => setShowModal(false);
    const handleModalShow = () => setShowModal(true);

    const handleLanguageChange = (lng) => {
        changeLanguage(lng);
    };

    const lang = [
        { title: "English", country: "Usa", value: "en" },
        { title: "Français", country: "France", value: "fr" },
        { title: "Español", country: "España", value: "es" },
    ]

    const renderLangButton = (props, ref) => {
        return (
            <div {...props} ref={ref} role='button' className='mx-2 text-dark d-flex justify-content-center align-items-center bg-dark-subtle rounded-5 p-2'>
                <img src={require(`../assets/flags/${currentLanguage}.png`)} width={16} height={10} alt={`${currentLanguage} Flag `} />
                <span className='mx-2'>|</span>
                <div>
                    {currentLanguage}
                </div>
            </div>
        );
    };

    return (
        <header style={{ position: 'relative' }} className='bg-white border-bottom w-100 fs-6 text-dark'>
            <div className="py-4 container" >
                <Row className='d-flex align-items-center justify-content-between'>
                    <Col xs="6" sm="6" md="3" lg="3" xl="2" className='d-flex justify-content-center'>
                        <img width="70%" height="70%" style={{ objectFit: "cover" }} src={logo} alt="Logo de notre agence de voyage" loading="lazy" />
                    </Col>
                    <Col md="7" lg="8" xl="5" className='d-none d-md-block'>
                        <NavBar />
                    </Col>
                    <Col xs="2" sm="2" md="2" lg="1" xl="1">
                        <Dropdown renderToggle={renderLangButton}>
                            <div className='px-3'>
                                {lang.map((lang, index) => (
                                    <a key={index} className='d-flex align-items-center p-3 text-dark lang-item rounded' href={location.pathname} onClick={() => handleLanguageChange(lang.value)}>
                                        <img src={require(`../assets/flags/${lang.value}.png`)} width="20%" height="20%" alt={`${lang.country} Flag `} />
                                        <span className='mx-2 fs-6 fw-medium'>{lang.title}</span>
                                    </a>
                                ))}
                            </div>
                        </Dropdown>
                    </Col>
                    <Col xl="3" className='justify-content-around align-items-center d-none d-xl-flex'>

                        <ShoppingCart strokeWidth={1.5} />
                        <User role='button' onClick={handleModalShow} strokeWidth={1.5}  />
                        <a href="/register" className='px-4 p-2 button-blue rounded'>{t('registration')}</a>
                    </Col>

                    <Col xs="3" sm="3" className='d-md-none d-flex align-items-center justify-content-center'>
                        <Menu className='header-btn-menu' type='button' size={30} onClick={handleShow} />
                    </Col>
                </Row>
            </div>

            <LeftMenu show={show} handleClose={handleClose} handleModalShow={handleModalShow} />
            <LoginLModal showModal={showModal} handleModalClose={handleModalClose} />
        </header>
    )
}

export default Header