import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NavBar = () => {

    const { t } = useTranslation();

    return (
        <nav className='w-100 d-flex align-items-center'>
            <ul className='header-nav-list fs-6'>
                <li>
                    <NavLink className='text-decoration-none' to="/">{t('home')}</NavLink >
                </li>
                <li>
                    <NavLink className='text-decoration-none' to="/solutions">{t('solutions')}</NavLink >
                </li>
                <li>
                    <NavLink className='text-decoration-none' to="/events">{t('events')}</NavLink >
                </li>
                <li>
                    <NavLink className='text-decoration-none' to="/contact">{t('contact')}</NavLink >
                </li>
            </ul>
        </nav>
    )
}

export default NavBar