import { Panel, PanelGroup } from 'rsuite';
import FaqData from '../data/faq.json'
import { useTranslation } from 'react-i18next';

const Faq = () => {

    const { t } = useTranslation();

    const elements = [
        { title: `1. ${t('payments')}`, data: FaqData.payment },
        { title: `2. ${t('bookings')}`, data: FaqData.booking },
        { title: `3. ${t('suggestions')}`, data: FaqData.suggetions },
    ]

    return (
        <section>
            <div className="bg-grey p-5 text-center">
                <h1 className='fw-medium text-dark'>{t('frequently_asked_questions')}</h1>
                <span className="fw-light fs-6">{t('home')} - {t('frequently_asked_questions')}</span>
            </div>

            <div className='container'>
                {elements.map((item, index) => (
                    <div className='my-5' key={index}>
                        <div className='fs-5 fw-medium text-dark'>{item.title}</div>
                        <PanelGroup accordion defaultActiveKey={0}>
                            {item.data.map((item, index) => (
                                <div key={index} className='my-3'>
                                    <Panel header={<div className='fs-6 fw-medium'>{item.title}</div>} collapsible bordered eventKey={index}>
                                        <div className='border-top py-3 fw-light fs-6'>
                                            <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                                        </div>
                                    </Panel>
                                </div>
                            ))}
                        </PanelGroup>
                    </div>
                ))}
            </div>

        </section>
    )
}

export default Faq