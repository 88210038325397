import { Col, Row } from "react-bootstrap"
import Input from '../../components/forms/Input'
import Textarea from "../../components/forms/Textarea"
import { useTranslation } from 'react-i18next';

const FormContact = () => {

  const { t } = useTranslation();

  return (
    <form>
      <fieldset className="fw-light fs-6">
        <div className='my-3'>
          <Input placeholder={t('full_name')} type="text" />
        </div>
        <Row>
          <Col lg="6">
            <div className='my-3'>
              <Input placeholder={t('email')} type="email" />
            </div>
          </Col>
          <Col lg="6">
            <div className='my-3'>
              <Input placeholder={t('phone')} type="phone" />
            </div>
          </Col>
        </Row>
        <div className='my-3'>
          <Textarea placeholder={t('your_message')} rows={6} />
        </div>
        <button className='button-orange p-4 rounded-0 text-dark w-100'>{t('send')}</button>
      </fieldset>
    </form>
  )
}

export default FormContact