import Availability from './forms/Availability'
import Activities from '../../components/recommendations/Activities'

const Home = () => {
    return (

        <section className='bg-body-tertiary'>
            <div className='py-5 bg-blue-subtle border-bottom'>
                <Availability />
            </div>
            <div className="container-xl py-5">
                <div className='text-center'>
                    <div className='text-dark fs-1 fw-medium'>Our best sellers</div>
                    <p className="my-1 fs-6">Find your activity</p>

                </div>
                <Activities />
            </div>
        </section>
    )
}

export default Home