import { Row, Col } from 'react-bootstrap'
import data from '../../data/activitiesdata.json'
import { Star, MapPin, Clock, Heart } from 'lucide-react'
import Pagination from '../../components/Pagination';



const FlexDisplay = () => {

  const renderSpecificItems = (items) => {
    return (
      <>
        <Row className='mt-3'>
          {items.map((activity, index) => (
            <Col key={index} xs="12" className="my-2">
              <a href={`/activities/${activity.id}`} className="text-dark">
                <div className='bg-white rounded-4 border recommendations-item' style={{ overflow: "hidden" }}>
                  <Row className='justify-content-start'>
                    <Col md="4">
                      <div className='position-relative h-100' style={{ overflow: "hidden" }}>
                        <img alt={`Vue d'ensemble de l'activité - ${activity.name}`} className='recommendations-item-img' width="100%" height="100%" style={{ objectFit: "cover" }} src={activity.img} />
                        <div className="position-absolute" title='Add to whishlist' style={{ top: "30px", right: "25px" }}>
                          <Heart fill='#00000036' size={30} strokeWidth={1.3} color="white" />
                        </div>
                      </div>
                    </Col>

                    <Col sm="7" md="5" className='py-3 d-flex align-items-center justify-content-center'>
                      <div className='p-3 border-end'>
                        <div className='d-flex align-items-center'><MapPin strokeWidth={1} size={20} />
                          <span className='mx-1'>{activity.country}</span>
                        </div>

                        <div style={{ fontSize: '18px' }} className='title-blue fw-bold my-2'>{activity.name}</div>

                        <div className='d-flex align-items-center my-3'>
                          <div className='d-flex align-items-center'><Star color='#FFB21D' fill='#FFB21D' size={15} /><span className='mx-2 fw-bold text-dark fs-6'>{activity.note}</span><span>({activity.reviews} Reviews)</span></div>
                        </div>

                        <p className='my-3 fs-6'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore</p>
                      </div>
                    </Col>

                    <Col sm="5" md="3" className='p-3 py-4'>
                      <div className='d-flex flex-column align-items-center justify-content-between h-100'>
                        <div className='d-flex align-items-center'><Clock strokeWidth={1} size={20} />
                          <span className='mx-1'>{activity.duration}</span>
                        </div>

                        <div>
                          <div className='mt-4'>
                            From: <span className='fw-bold text-dark' style={{ fontSize: "16px" }}>€{activity.price}</span>
                          </div>
                          <button className='button-blue my-3 py-3 px-4 rounded-5 fw-bold'>View details</button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </a>
            </Col>
          ))}
        </Row>
      </>
    );
  };


  return (
    <>
      <Pagination data={data.activities} itemsPerPage={9} renderItems={renderSpecificItems} />
    </>
  )
}

export default FlexDisplay