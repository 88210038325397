import { Row, Col } from 'react-bootstrap';
import { Check } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function Events() {

  const { t } = useTranslation();

  const words = [t('opportunities'), t('partenarships'), t('skills')];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000);

    return () => clearInterval(intervalId);
  });


  return (

    <section style={{background:"#F5F5F5"}}>
      <div className='container'>
        <Row>
          <Col md="12" xl="6">
            <div className='text-dark p-5'>
              <span className='text-blue'>{t('get_started_today')}</span>
              <h1 className='fw-medium my-3'>{t('events_title')}<div className='word-container'><br /><span className='pe-2 text-blue word-animation'>{words[currentWordIndex]}</span></div></h1>
              <p className='my-4 fs-6 fw-light'>{t('events_text')}</p>
              <div className='my-4 fw-light fs-6'>
                <div className='my-3 text-blue'><Check size={15} /> <span className='text-dark mx-1'>{t('events_feature1')}</span></div>
                <div className='my-3 text-blue'><Check size={15} /> <span className='text-dark mx-1'>{t('events_feature2')}</span></div>
                <div className='my-3 text-blue'><Check size={15} /> <span className='text-dark mx-1'>{t('events_feature3')}</span></div>
              </div>
              <button className='button-blue p-3 px-5 my-4'>{t('subscribe')}</button>
            </div>
          </Col>
          <Col xl="6">
            <img width="100%" height="100%" src='https://insurance.ancorathemes.com/wp-content/uploads/2023/02/115_3-1-copyright.jpg' alt='Event illusatration' />
          </Col>
        </Row>
      </div>
    </section>
  )
}

export default Events