import Background from '../components/home/Background'
import Steps from '../components/home/Steps'
import Reviews from '../components/home/Reviews'
import Services from '../components/home/Services'
import Plans from '../components/home/Plans'
import Reasons from '../components/home/Reasons'
import Resposive from '../components/home/Resposive'
import EventsCategory from '../components/home/EventsCategory'

const Main = () => {
    return (
        <main>
            <Background />
            <Services />
            <Reasons />
            <EventsCategory/>
            
            <Steps />
            <div className='d-none d-lg-block'>
                <Reviews />
            </div>
            <Resposive/>
            
            <Plans />
        </main>
    )
}

export default Main