import { useParams } from "react-router-dom";
import details from '../../data/activitiesdata.json'
import { Star, CalendarX2, CalendarClock, Clock, Flag, Check, X, MapPin, CalendarCheck, Heart, Users2 } from 'lucide-react'
import { Row, Col } from 'react-bootstrap'
import React, { useState } from 'react';
import Reviews from '../../components/Reviews'
import WriteReview from '../../components/WriteReview'
import ImagesCarousel from '../../components/ImagesCarousel'
import GuestsCount from './forms/GuestsCount';
import { DateRangePicker } from "rsuite";

function Details() {

    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const { id } = useParams();
    let activity = details.activities.filter(objet => objet.id === parseInt(id))[0];

    const shortDescription = activity.full_decription.substring(0, 200) + "...";
    const [isFullDescriptionVisible, setIsFullDescriptionVisible] = useState(false);

    const toggleDescription = () => {
        setIsFullDescriptionVisible(!isFullDescriptionVisible);
    }


    const [adultCount] = useState(1);
    const [childCount] = useState(0);
    const [infantCount ] = useState(0);

    const totalCount = parseInt(adultCount) + parseInt(childCount) + parseInt(infantCount)

    const [showActivityDetails, setShowActivityDetails] = useState(false);

    const toggleActivityDetails = () => {
        setShowActivityDetails(!showActivityDetails);
    };

    const scrollToSection = (sectionId) => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
            sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
    };


    return (
        <section>
            <div className="container-xl p-3">
                <span className="text-uppercase text-secondary fw-bold">{activity.type}</span>
                <h2 className="text-dark">{activity.name}</h2>
                <div className="my-3">
                    <div className='d-flex align-items-center'>
                        {Array.from({ length: activity.note }).map((_, starIndex) => (
                            <div key={starIndex} className='me-1'><Star color='#F8D448' fill='#F8D448' size={13} /></div>
                        ))}
                        <span className="mx-2 fw-medium fs-6 text-dark">{activity.note} / 5</span>
                        <span className="mx-2 fw-medium text-blue text-decoration-underline">{activity.reviews} reviews</span>
                        <span>Activity provider: <span className="mx-2 fw-medium text-blue text-decoration-underline">{activity.provider}</span></span>
                    </div>
                </div>

                {/* Image carousel */}
                <ImagesCarousel showModal={showModal} closeModal={closeModal} item={activity} />

                <div role="button" onClick={openModal}  className="position-relative">
                    <Row className="my-3">
                        <Col lg="8" className="px-1">
                            <img width="100%" height="100%" alt={`Ilustration 1 de l'activité`} src={activity.images[0]} style={{ maxHeight: "400px", minHeight: "450px" }} />
                        </Col>
                        <Col lg="4" className="d-none d-lg-block">
                            <Row style={{ rowGap: '10px' }}>
                                <Col md="6" className="px-1">
                                    <img width="100%" height="100%" alt={`Ilustration 2 de l'activité`} src={activity.images[1]} style={{ minHeight: "220px", maxHeight: "220px" }} />
                                </Col>
                                <Col md="6" className="px-1">
                                    <img width="100%" height="100%" alt={`Ilustration 2 de l'activité`} src={activity.images[5]} style={{ minHeight: "220px", maxHeight: "220px" }} />
                                </Col>
                                <Col md="6" className="px-1">
                                    <img width="100%" height="100%" alt={`Ilustration 3 de l'activité`} src={activity.images[3]} style={{ minHeight: "220px", maxHeight: "220px" }} />
                                </Col>
                                <Col md="6" className="px-1">
                                    <img width="100%" height="100%" alt={`Ilustration 4 de l'activité`} src={activity.images[4]} style={{ minHeight: "220px", maxHeight: "220px" }} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <button className="bg-white rounded position-absolute top-0 end-0 m-3 p-2 text-dark fw-medium fs-6">
                        Add to wishlist <Heart size={20} />
                    </button>

                    <button className="bg-white rounded position-absolute top-0 start-0 m-3 p-2 text-dark fw-medium fs-6">
                        View all {activity.images.length} images
                    </button>
                </div>

                <Row className="my-5">
                    <Col lg="8">
                        <div>
                            <p className="fs-6 fw-bold text-dark">{activity.description}</p>
                            <div className="fs-4 text-dark fw-bold mt-3">About this tour</div>
                            <div className="my-3 text-dark">
                                <div className="d-flex my-4">
                                    <Users2 />
                                    <div className="mx-2">
                                        <div className="fs-6 fw-bold text-dark">Group size</div>
                                        <p className="fs-6 fw-medium">15</p>
                                    </div>
                                </div>
                                <div className="d-flex my-4">
                                    <CalendarX2 />
                                    <div className="mx-2">
                                        <div className="fs-6 fw-bold text-dark">{activity.cancel}</div>
                                        <p className="fs-6 fw-medium">{activity.cancel_text}</p>
                                    </div>
                                </div>
                                <div className="d-flex my-4">
                                    <Clock />
                                    <div className="mx-2">
                                        <div className="fs-6 fw-bold text-dark">Duration {activity.duration}</div>
                                        <p className="fs-6 fw-medium">Check availability to see starting times.</p>
                                    </div>
                                </div>
                                <div className="d-flex my-4">
                                    <Flag />
                                    <div className="mx-2">
                                        <div className="fs-6 fw-bold text-dark">Live tour guide</div>
                                        <div>{activity.guide.map((item, index) => (
                                            <span key={index} className="fs-6 fw-medium">{item}, </span>
                                        ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="availability" className="rounded-4 p-3" style={{ background: "#1A2B49" }}>
                            <span className="fs-5 text-white fw-bold">Select participants and date</span>
                            <Row className="mt-3 align-items-center">
                                <Col lg="6" className="my-2">
                                    <div className="bg-white p-2 rounded-5">
                                        <GuestsCount />
                                    </div>
                                </Col>
                                <Col lg={!showActivityDetails ? "3" : "6"} className="my-2">
                                    <div className="p-3 bg-white rounded-5 d-flex justify-content-center">
                                        <DateRangePicker />
                                    </div>
                                </Col>
                                {!showActivityDetails ?
                                    <Col lg="3" className="my-2">
                                        <button className="btn-blue p-3 w-100 rounded-5" onClick={toggleActivityDetails}>Check availability</button>
                                    </Col>

                                    : ''
                                }
                            </Row>
                        </div>

                        <div className="my-4 text-dark fw-bold">
                            {showActivityDetails && (
                                <div className="border rounded-4 border-primary border-2 element" style={{ overflow: "hidden" }}>
                                    <div className="p-3">
                                        <span className="fs-5">{activity.name}</span>
                                        <div className="d-flex align-items-center my-3">
                                            <Clock />
                                            <span className="mx-2">{activity.duration}</span>
                                        </div>

                                        <div className="d-flex align-items-center my-3 text-blue">
                                            <MapPin />
                                            <span className="mx-2 text-decoration-underline">{activity.meeting_point}</span>
                                        </div>

                                        <hr className="my-3 border-secondary" />

                                        <div className="fs-6">Starting time</div>
                                        <div className="fs-6 fw-medium mt-2">8:00 AM</div>

                                        <hr className="my-3 border-secondary" />

                                        <Row>
                                            <Col md="6" className="my-2">
                                                <div className="d-flex">
                                                    <CalendarCheck size={25} />
                                                    <p className="fs-6 mx-2">Cancel before 8:00 AM on December 29 for a full refund</p>
                                                </div>
                                            </Col>
                                            <Col md="6" className="my-2">
                                                <div className="fs-6">
                                                    <div>Price breakdown</div>
                                                    <p className="text-secondary mt-2">Adult1 × € 76.72</p>
                                                </div>
                                            </Col>
                                            <Col md="6" className="my-2">
                                                <div className="d-flex">
                                                    <CalendarClock size={25} />
                                                    <p className="fs-6 mx-2">You can reserve now & pay later with this activity option.</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="p-3" style={{ background: "#EBEEF1" }}>
                                        <Row className="justify-content-between align-items-center">
                                            <Col md="6" className="my-2">
                                                <div>
                                                    <div>Total price</div>
                                                    <div className="fs-4 text-warning">€ {parseFloat((activity.price * totalCount).toFixed(2))}</div>
                                                    <div className="my-1">
                                                        <span className="text-decoration-line-through">€ 85.24</span> <span className="text-warning mx-2">-10%</span>
                                                    </div>
                                                    <div className="fw-medium">All taxes and fees included</div>
                                                </div>
                                            </Col>
                                            <Col md="3" className="my-2">
                                                <div>
                                                    <a href={`/booking/activity/${activity.id}`} className="button-blue p-3 rounded-5">Add to cart</a>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>

                                </div>
                            )}
                        </div>

                        <div className="mt-5">
                            <div className="fs-4 text-dark fw-bold">The Original experience</div>

                            <Row className="my-3 fs-6 fw-medium text-dark">
                                <Col md="3">
                                    <span className="fs-6 fw-bold">Highlights</span>
                                </Col>
                                <Col md="9">
                                    <div dangerouslySetInnerHTML={{ __html: activity.highlights }}></div>
                                </Col>
                            </Row>

                            <hr className="border-secondary my-3" />

                            <Row className="my-3 fs-6 fw-medium text-dark">
                                <Col md="3">
                                    <span className="fs-6 fw-bold">Description</span>
                                </Col>
                                <Col md="9">
                                    <div>
                                        <p dangerouslySetInnerHTML={{ __html: isFullDescriptionVisible ? activity.full_decription : shortDescription }}></p>
                                        <span role="button" className="text-blue text-decoration-underline mt-5" onClick={toggleDescription}>
                                            {isFullDescriptionVisible ? "See less" : "See more"}
                                        </span>
                                    </div>
                                </Col>
                            </Row>

                            <hr className="border-secondary my-3" />

                            <Row className="my-3 fs-6 fw-medium text-dark">
                                <Col md="3">
                                    <span className="fs-6 fw-bold">Includes</span>
                                </Col>
                                <Col md="9">
                                    <div>
                                        {activity.included.map((item, index) => (
                                            <div className="d-flex" key={index}>
                                                <div className="text-success">
                                                    <Check size={15} />
                                                </div>
                                                <span className="mx-2 fs-6 fw-medium">{item}</span>
                                            </div>
                                        ))}
                                    </div>
                                    <div>
                                        {activity.not_included.map((item, index) => (
                                            <div className="d-flex" key={index}>
                                                <div className="text-danger">
                                                    <X size={15} />
                                                </div>
                                                <span className="mx-2 fs-6 fw-medium">{item}</span>
                                            </div>
                                        ))}
                                    </div>
                                </Col>
                            </Row>

                            <hr className="border-secondary my-3" />

                            <Row className="my-3 fs-6 fw-medium text-dark">
                                <Col md="3">
                                    <span className="fs-6 fw-bold">Not suitable for
                                    </span>
                                </Col>
                                <Col md="9">
                                    <span dangerouslySetInnerHTML={{ __html: activity.not_suitable }}></span>
                                </Col>
                            </Row>

                            <hr className="border-secondary my-3" />

                            <Row className="my-3 fs-6 fw-medium text-dark">
                                <Col md="3">
                                    <span className="fs-6 fw-bold">Meeting point
                                    </span>
                                </Col>
                                <Col md="9">
                                    <div>
                                        <p>{activity.meeting_point}</p>
                                        <div className="mt-3">
                                            <a href="/map" className="text-blue">Open in Google Maps</a>
                                        </div>
                                    </div>
                                </Col>
                            </Row>

                            <hr className="border-secondary my-3" />

                            <Row className="my-3 fs-6 fw-medium text-dark">
                                <Col md="3">
                                    <span className="fs-6 fw-bold">Important information</span>
                                </Col>
                                <Col md="9">
                                    <div>
                                        <div className="fs-6 fw-bold">What to bring</div>
                                        <div className="mt-1" dangerouslySetInnerHTML={{ __html: activity.informations.what_to_bring }}></div>
                                    </div>
                                    <div className="my-4">
                                        <div className="fs-6 fw-bold">Not allowed</div>
                                        <div className="mt-1" dangerouslySetInnerHTML={{ __html: activity.informations.not_allowed }}></div>
                                    </div>
                                    <div>
                                        <div className="fs-6 fw-bold">Know before you go</div>
                                        <div className="mt-1" dangerouslySetInnerHTML={{ __html: activity.informations.know_before_you_go }}></div>
                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                    <Col lg="4">
                        <div className="d-flex flex-column border border-2 p-3 text-dark">
                            <div className="text-white bg-danger align-self-start p-1 rounded">
                                Likely to sell out
                            </div>
                            <div className="d-flex align-items-center justify-content-between my-4">
                                <div>
                                    <div className="fs-6 fw-bold">From</div>
                                    <div className="fs-4 fw-bold">€ {activity.price}</div>
                                    <div className="fs-6 fw-bold">per person</div>
                                </div>
                                <button className="button-blue px-5 p-3 rounded-5" onClick={() => scrollToSection('availability')}>Check availability</button>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                    <CalendarClock />
                                </div>
                                <p className="mx-3 fw-bold"><span className="text-blue text-decoration-underline">Reserve now & pay later</span> to book your spot and pay nothing today</p>
                            </div>
                        </div>
                        <div>
                            <Reviews/>
                        </div>
                    </Col>
                </Row>

                <hr className="border-secondary my-4" />

                <div>
                    <WriteReview />
                </div>

            </div>

        </section>

    );

}

export default Details;
