import { ClipboardList, Hand, Lightbulb } from 'lucide-react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

const Planification = () => {

    const { t } = useTranslation();

    const reservation = [
        { title: t('planning_title1'), description: t('planning_description1'), icone: <Hand strokeWidth={1.5} size={50} /> },
        { title: t('planning_title2'), description: t('planning_description2'), icone: <ClipboardList strokeWidth={1.5} size={50} /> },
        { title: t('planning_title3'), description: t('planning_description3'), icone: <Lightbulb strokeWidth={1.5} size={50} /> }
    ]


    return (
        <Row className='my-3'>
            <Col xs="12" xl="6" className='my-3'>
                <img width="100%" height="100%" style={{ objectFit: "cover" }} alt="Un cadre supérieur qui effctue son voyage d'affaire" loading='lazy' src="https://wgl-demo.net/brilix/wp-content/uploads/2023/11/portfolio-s3-1000x600.jpg" />
            </Col>

            <Col xs="12" xl="6" className='ps-xl-5 pe-xl-5 d-flex flex-column justify-content-around my-3'>

                <div className='text-dark fw-bold fs-2'>{t('planning')}</div>
                <p className='fs-5 fw-light'>
                {t('planning_intro')}
                </p>

                {reservation.map((reservation, index) => (
                    <Row className='my-3' key={index}>
                        <Col md="2" className='p-4 text-blue'>
                            {reservation.icone}
                        </Col>
                        <Col md="10">
                            <span className='text-dark fs-5 fw-medium'>{reservation.title}</span>
                            <p className='fs-5 fw-light mt-2' dangerouslySetInnerHTML={{ __html: reservation.description }}></p>
                        </Col>
                    </Row>
                ))}
            </Col>
        </Row>
    )
}

export default Planification