import { Col, Row } from 'react-bootstrap'
import { Panel, Rate } from 'rsuite'
import Input from './forms/Input'
import Select from './forms/Select'
import Textarea from './forms/Textarea'

const WriteReview = () => {

    const ratedata = [
        { title: 'Rate', value: '' },
        { title: '1', value: 1 },
        { title: '2', value: 2 },
        { title: '3', value: 3 },
        { title: '4', value: 4 },
        { title: '5', value: 5 },
    ]

    return (
        <Panel defaultExpanded header={<button className="button-blue fw-bold fs-5 p-3 rounded-5">Write a review</button>} collapsible>
            <form className='my-3'>
                <fieldset>
                    <legend className='text-dark fw-bold fs-3'>Leave a review</legend>
                    <p className='fs-6 my-2'>Your email address will not be published.</p>
                    <Row className='mt-3'>
                        <Col xl="4" className='my-2'>
                            <Input placeholder="Name *" type="text" />
                        </Col>

                        <Col xl="4" className='my-2'>
                            <Input placeholder="Email *" type="email" />
                        </Col>

                        <Col xl="4" className='my-2'>
                            <Select data={ratedata} />
                        </Col>

                        <Col xl="4" className='my-2 h-100'>
                            <div className='w-100 border rounded-3 p-3 h-100 mt-1'>
                                <Row className='my-2'>
                                    <Col xs="6">
                                        <span className='fs-6'>Cleanliness</span>
                                    </Col>
                                    <Col xs="6">
                                        <Rate className='mx-1' defaultValue={3} size="xs" />
                                    </Col>
                                </Row>
                                <Row className='my-2'>
                                    <Col xs="6">
                                        <span className='fs-6'>Staff & service</span>
                                    </Col>
                                    <Col xs="6">
                                        <Rate className='mx-1' defaultValue={3} size="xs" />
                                    </Col>
                                </Row>
                                <Row className='my-2'>
                                    <Col xs="6">
                                        <span className='fs-6'>Property conditions</span>
                                    </Col>
                                    <Col xs="6">
                                        <Rate className='mx-1' defaultValue={3} size="xs" />
                                    </Col>
                                </Row>
                                <Row className='my-2'>
                                    <Col xs="6">
                                        <span className='fs-6'>Location</span>
                                    </Col>
                                    <Col xs="6">
                                        <Rate className='mx-1' defaultValue={3} size="xs" />
                                    </Col>
                                </Row>
                            </div>
                        </Col>

                        <Col xl="8" className='my-2'>
                            <Textarea rows={7} placeholder='Content *' />
                        </Col>
                    </Row>
                </fieldset>
            </form>
        </Panel>
    )
}

export default WriteReview