import Main from './layout/Main'
import ErrorPage from './pages/404'
import { Route, Routes } from 'react-router-dom';
import MainSolutions from './pages/solutions/MainSolutions'
import MainEvents from './pages/events/MainEvents'
import FlightMain from './pages/flights/Main'
import HotelMain from './pages/hotels/Main'
import ActivityMain from './pages/activities/Main'
import TransfertMain from './pages/transferts/Main'
import CarMain from './pages/cars/Main'
import BookingMain from './pages/booking/Main'
import Contact from './pages/contact/MainContact'
import About from './pages/About'
import Terms from './pages/Terms'
import Faq from './pages/Faq';
import Pricing from './pages/Pricing';
import Register from './components/Register'

function RouteComponents() {
    return (
        <Routes>
            <Route path='/' exact element={<Main />} />

            <Route path="/solutions" element={<MainSolutions />}></Route>
            <Route path="/events" element={<MainEvents />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/terms" element={<Terms />}></Route>
            <Route path="/pricing" element={<Pricing />}></Route>
            <Route path="/faq" element={<Faq />}></Route>
            <Route path="/register" element={<Register />}></Route>

            <Route path="/flights">
                <Route path='' element={<FlightMain component="home" />} />
                <Route path=':id' element={<FlightMain component="details" />} />
                <Route path='search' element={<FlightMain component="search" />} />
            </Route>

            <Route path="/hotels">
                <Route path='' element={<HotelMain component="home" />} />
                <Route path=':id' element={<HotelMain component="details" />} />
                <Route path='search' element={<HotelMain component="search" />} />
            </Route>

            <Route path="/activities">
                <Route path='' element={<ActivityMain component="home" />} />
                <Route path='search' element={<ActivityMain component="search" />} />
                <Route path=':id' element={<ActivityMain component="details" />} />
            </Route>

            <Route path="/transferts">
                <Route path='' element={<TransfertMain component="home" />} />
                <Route path='search' element={<TransfertMain component="search" />} />
            </Route>

            <Route path="/cars">
                <Route path='' element={<CarMain component="home" />} />
                <Route path='search' element={<CarMain component="search" />} />
                <Route path=':id' element={<CarMain component="details" />} />
            </Route>

            <Route path="/booking">
                <Route path='wishlist' element={<BookingMain component="wishlist" />} />
                <Route path='hotel/:id' element={<BookingMain component="book_hotel" />} />
                <Route path='flight/:id' element={<BookingMain component="book_flight" />} />
                <Route path='activity/:id' element={<BookingMain component="book_activity" />} />
                <Route path='package/:id' element={<BookingMain component="book_package" />} />
                <Route path='car/:id' element={<BookingMain component="book_car" />} />
                <Route path='transportation/:id' element={<BookingMain component="book_transportation" />} />
                <Route path='cart/:id/:type' element={<BookingMain component="cart" />} />
                <Route path='payment' element={<BookingMain component="payment" />} />
                <Route path='voucher/:hotelId' element={<BookingMain component="voucher" />} />
            </Route>

            <Route path='*' element={<ErrorPage />} />
        </Routes>

    );
}
export default RouteComponents;
