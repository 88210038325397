import { CheckCircle2 } from 'lucide-react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

const Resposive = () => {

    const { t } = useTranslation();

    const elements = [
        { title: t('responsive_feature1'), content: t('responsive_description1') },
        { title: t('responsive_feature2'), content: t('responsive_description2') },
        { title: t('responsive_feature3'), content: t('responsive_description3') },
    ]
    return (
        <div className='container-xl bg-orange rounded py-5 my-5'>
            <Row className='align-items-center'>
                <Col lg="6">
                    <div className='w-100'>
                        <img width="100%" height="100%" alt='Responsive Illustration' src={require('../../assets/home/responsive-img.png')} />
                    </div>
                </Col>
                <Col lg="6">
                    <div className='p-3 p-md-5 fs-6 text-dark'>
                        <div className='text-blue fw-medium'>{t('explore_and_enjoy')}</div>
                        <p className='fs-1 fw-medium'>{t('responsive_title')}</p>
                        <div className='mt-4'>
                            {elements.map((item, index) => (
                                <div key={index} className='d-flex text-blue my-4'>
                                    <CheckCircle2 strokeWidth={1.5} size={30} />
                                    <div className='mx-2 text-dark'>
                                        <div className='fs-5 fw-medium'>{item.title}</div>
                                        <p className='fw-light'>{item.content}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Resposive