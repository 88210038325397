import React from "react";
import { Col, Row } from "react-bootstrap";
import { Progress } from "rsuite";
import { useTranslation } from "react-i18next";

const Securite = () => {
  const { t } = useTranslation();

  const risks = [
    { title: t("security_title1"), description: t("security_description1"), pourcent: 100 },

    { title: t("security_title2"), description: t("security_description2"), pourcent: 3 },

    { title: t("security_title3"), description: t("security_description3"), pourcent: 90 },
  ];

  const style = {
    width: "80%",
    display: "inline-block",
  };

  return (
    <Row className="my-5 py-3">
      <Col className="d-none d-lg-block" lg="4" xl="4">
        <img src="https://images.pexels.com/photos/5257900/pexels-photo-5257900.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Femme planifiant les risques de voyages" style={{ objectFit: "cover" }} width="100%" height="100%" loading="lazy" />
      </Col>
      <Col md="12" lg="8" xl="8">
        <div className="text-dark fw-bold fs-2">{t("security")}</div>
        <p className="fs-5 fw-light">{t("security_intro")}</p>

        <Row className="justify-content-between">
          {risks.map((risk, index) => (
            <Col lg="12" key={index}>
              <Row className="my-3">
                <Col sm="2" className="d-none d-sm-block p-4 pe-0 text-orange">
                  <div style={style}>
                    <Progress.Circle percent={risk.pourcent} strokeColor="#F25E52" />
                  </div>
                </Col>
                <Col xs="12" sm="10">
                  <span className="text-dark fs-5 fw-medium">{risk.title}</span>
                  <p className="fs-5 fw-light mt-2" dangerouslySetInnerHTML={{ __html: risk.description }}></p>
                </Col>
              </Row>
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
};

export default Securite;
