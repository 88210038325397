import { Modal } from 'react-bootstrap';
import { Lock, LogIn, Mail, MoveRight } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const LoginLModal = ({ showModal, handleModalClose }) => {

    const { t } = useTranslation();

    return (
        <>
            <Modal show={showModal} onHide={handleModalClose} className='p-0 rounded-4'>
                <div className='d-flex flex-column align-items-center p-5 pb-0'>
                    <div className='text-blue'><LogIn size={50} /></div>
                    <div className='fs-1 fw-bold my-2 text-dark'>{t('welcome')}</div>
                    <div className='fs-5'>{t('sign_in_to_your_account')}</div>
                </div>
                <Modal.Body className='p-0'>
                    <form>
                        <div className='px-3'>
                            <div className='d-flex align-items-center border-bottom my-4'>
                                <input placeholder={t('email')} className='py-4 rounded-0 w-100' />
                                <div className='p-4 d-flex justify-content-center align-items-center' style={{ height: "2cm" }}>
                                    <Mail />
                                </div>
                            </div>
                            <div className='d-flex align-items-center border-bottom my-4'>

                                <input placeholder={t('password')} className='py-4 rounded-0 w-100' />
                                <div className='p-4 d-flex justify-content-center align-items-center' style={{ height: "2cm" }}>
                                    <Lock />
                                </div>
                            </div>

                            <div className='d-flex justify-content-around'>
                                <div className='d-flex align-items-center'>
                                    <input type='checkbox' />
                                    <label className='mx-2'>{t('remember_me')}</label>
                                </div>
                                <a href='/password'>{t('forgot_password')}</a>
                            </div>
                        </div>
                        <div className='p-3 mt-3'>
                            <button className='button-blue rounded p-4'>{t('log_in')} <MoveRight className='mx-2'/></button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default LoginLModal