import { Panel } from 'rsuite';
import FilterMap from '../../components/FilterMap'
import FilterPrice from '../../components/FilterPrice';

const Filters = () => {

    const model = [
        { name: "Honda" },
        { name: "Hyundai" },
        { name: "Kia" },
        { name: "Seat" },
    ]

    const service = [
        { name: "Screen" },
        { name: "Conditionner" },
        { name: "Gps" },
        { name: "Baby seat" },
        { name: "Storage" },
        { name: "Wifi" },
    ]

    const color = [
        { name: "Blue" },
        { name: "Red" },
        { name: "Black" },
        { name: "White" },
        { name: "Green" },
        { name: "Orange" },
        { name: "Yellow" },
    ]



    return (
        <>
            <div className='d-none d-xl-block position-relative'>
                <FilterMap/>
            </div>

            <div className="my-3 rounded-4 p-xl-3 border bg-white">
                <FilterPrice/>
            </div>

            <div className="my-3 rounded-4 p-xl-3 border bg-white">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Model</span>} collapsible>
                    {model.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'><input type='checkbox' /><span className='my-1 mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-3 border bg-white">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Features</span>} collapsible>
                    {service.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center my-1'><input type='checkbox' /><span className='mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-xl-3 border bg-white">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Color</span>} collapsible>
                    {color.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'><input type='checkbox' /><span className='my-1 mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

        </>
    )
}

export default Filters