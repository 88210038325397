const Input = ({ id, name, value, handleChange, placeholder, type, maxlength }) => {

    return (

        <>
            <input name={name} id={id} placeholder={placeholder} type={type} onChange={handleChange} defaultValue={value} className='w-100 p-4 border' maxLength={maxlength} />
        </>
    )
}

export default Input