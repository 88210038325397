import logo from '../assets/main-logo-white.svg'
import { Row, Col } from 'react-bootstrap';
import { BellRing, Facebook, Instagram, Twitter, Linkedin, ChevronsRight } from 'lucide-react';
import company from '../data/company.json'
import footerdata from '../data/footerdata.json'
import { useTranslation } from 'react-i18next';



function Footer() {

    const currentYear = new Date().getFullYear();
    const { t } = useTranslation();


    return (
        <footer className='text-white p-3 p-sm-5 fs-6' style={{ background: "#0E1E36" }}>
            <div className='container py-5'>
                <Row className='justify-content-between'>
                    <Col lg="4">
                        <img src={logo} alt='logo agency' />
                        <p className='mt-3 fw-lighter'>{t('footer_text')}</p>
                    </Col>
                    <Col lg="6">
                        <div className='text-blue'>
                            <BellRing /> <span className='ms-2 text-white'>{t('subscribe_text')}</span>
                        </div>
                        <div className='mt-3 border-blue p-2'>
                            <input className='w-75 ps-3' placeholder={t('enter_your_email_address')} style={{ background: "transparent" }} />
                            <button className='button-blue p-3 w-25'>{t('subscribe_now')}</button>
                        </div>
                    </Col>
                </Row>
                <Row className='mt-5'>
                    <Col lg="3">
                        <span className='fs-5'>{t('office')}</span>
                        <div className='my-3 fw-lighter'>
                            <div>{company.adress}</div>
                            <div className='my-2'>{company.contact}</div>
                            <div>{company.working_hours}</div>
                        </div>
                    </Col>
                    <Col lg="3">
                        <span className='fs-5'>{t('quick_link')}</span>
                        <div className='my-3 fw-lighter'>
                            {footerdata.quiklinks.map((item, index) => (
                                <div key={index} className='my-2'>
                                    <a href={item.href} className='text-white'><ChevronsRight size={15} strokeWidth={1.5} /> {item.title}</a>
                                </div>
                            ))}

                        </div>
                    </Col>
                    <Col lg="3">
                        <span className='fs-5'>{t('services')}</span>
                        <div className='my-3 fw-lighter'>
                            {footerdata.services.map((item, index) => (
                                <div key={index} className='my-2'>
                                    <a href={item.href} className='text-white'>{item.title}</a>
                                </div>
                            ))}

                        </div>
                    </Col>
                    <Col lg="3">
                        <span className='fs-5'>{t('latest_news')}</span>
                        <div className='my-3 d-flex'>
                            <img width={70} height={70} src='https://demo-themewinter.com/exhibz/education/wp-content/uploads/sites/4/2019/01/blog2-750x465.jpg' alt='blog article' />
                            <div className='mx-2'>
                                <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit</div>
                                <div className='fw-lighter'>Nov 15, 2023 </div>
                            </div>
                        </div>
                        <hr className='my-4' />
                        <div className='d-flex'>
                            <img width={70} height={70} src='https://demo-themewinter.com/exhibz/education/wp-content/uploads/sites/4/2019/01/blog1-750x465.jpg' alt='blog article' />
                            <div className='mx-2'>
                                <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit</div>
                                <div className='fw-lighter'>Nov 15, 2023 </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <hr className='my-5 border-2' />
                <div className='d-flex justify-content-between'>
                    <div className='fw-light'>
                        © {currentYear} <span className='text-blue'>IDHCORPORATE</span> | {t('all_rights_reserved')}
                    </div>
                    <div className='mx-3'>
                        <a className='me-3 text-white' aria-label="Lien vers notre page facebook" href="https://www.facebook.com/Idhtour"><Facebook strokeWidth={1} size={20} /></a>
                        <a className='me-3 text-white' aria-label="Lien vers notre page instagram" href="https://www.instagram.com/idhtours"><Instagram strokeWidth={1} size={20} /></a>
                        <a className='me-3 text-white' aria-label="Lien vers notre page Twitter" href="https://twitter.com/ContactIdhtours"><Twitter strokeWidth={1} size={20} /></a>
                        <a className='me-3 text-white' aria-label="Lien vers notre page LinkedIn" href="https://www.linkedin.com/in/idh-tours"><Linkedin strokeWidth={1} size={20} /></a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer