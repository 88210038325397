import BookHotel from './BookHotel'
import BookFlight from './BookFlight'
import BookActivity from './BookActivity'
import BookCar from './BookCar'
import Cart from './Cart';
import Payment from './Payment'
import Voucher from './Voucher';

function Main(props) {

    const composite = {
        
        book_hotel:<BookHotel/>,
        book_flight:<BookFlight/>,
        book_activity:<BookActivity/>,
        book_car:<BookCar/>,
        cart:<Cart/>,
        payment:<Payment/>,
        voucher:<Voucher/>
    };

    return (
        <>
            {eval("composite." + props.component)}
        </>
    );
}

export default Main;