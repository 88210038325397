import BackgroundSolutions from './BackgroundSolutions'
import SolutionsItems from './SolutionsItems'

function MainSolutions(){ 

    return(

       <main>
            <BackgroundSolutions/>
            <SolutionsItems/>
       </main>
        
    )
}

export default MainSolutions