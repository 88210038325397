import { Users, PlaneLanding, PlaneTakeoff } from "lucide-react"
import { Row, Col } from 'react-bootstrap'
import React, { useState } from 'react';
import Guests from "./Guests";
import InputCity from '../../../components/forms/InputCity'
import DateInput from '../../../components/forms/DateInput'

const OneWay = () => {
    const [AdultsCount, setAdultsCount] = useState(1);

    const handlesetAdultsCount = (event) => {
        setAdultsCount(event.target.value);
    };

    const [ChildsCount, setChildsCount] = useState(0);

    const handlesetChildsCount = (event) => {
        setChildsCount(event.target.value);
    };

    const [BabiesCount, setBabiesCount] = useState(0);

    const handlesetBabiesCount = (event) => {
        setBabiesCount(event.target.value);
    };

    const [ClasseValue, setClasseValue] = useState('Toutes');

    const handleClasseValue = (event) => {
        setClasseValue(event.target.value);
    };


    const GuestsCount = parseInt(AdultsCount) + parseInt(ChildsCount) + parseInt(BabiesCount)

    const [showGuests, setShowGuests] = useState(false);

    const handleCloseGuests = () => setShowGuests(false);
    const handleShowGuests = () => setShowGuests(true);

    return (
        <div className='bg-white border text-dark p-lg-3 w-100' style={{ borderRadius: '40px' }}>
            <Row className="px-1 align-items-center">
                <Col lg="2">
                    <InputCity id="location" label="Flying from" placeholder="Origin" icon={<PlaneTakeoff strokeWidth={1} size={20} className="form-icon" />} />
                </Col>

                <Col lg="2" className="border-end">
                    <InputCity id="destination" label="Flying to" placeholder="Arrival" icon={<PlaneLanding strokeWidth={1} size={20} className="form-icon" />} />
                </Col>

                <Col lg="5">
                    <DateInput rightLabel="Start date" leftLabel="Return date" />
                </Col>

                <Col lg="3" className="border-start">
                    <div role="button" className="w-100 d-flex align-items-center p-3 p-lg-0" onClick={handleShowGuests} >
                        <Users strokeWidth={1} size={20} className="form-icon" />
                        <div className="d-flex flex-column mx-3">
                            <span htmlFor="location" className="fs-6 fw-medium title-blue">Travelers</span>
                            <div className="text-secondary">{GuestsCount} Guest, {ClasseValue}</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Guests showGuests={showGuests} handleCloseGuests={handleCloseGuests} handleShowGuests={handleShowGuests} AdultsCount={AdultsCount} handlesetAdultsCount={handlesetAdultsCount} ChildsCount={ChildsCount} handlesetChildsCount={handlesetChildsCount} ClasseValue={ClasseValue} handleClasseValue={handleClasseValue} GuestsCount={GuestsCount} handlesetBabiesCount={handlesetBabiesCount} />
        </div>
    )
}

export default OneWay