import { useParams } from "react-router-dom";
import details from '../../data/flightdata.json'
import { MoveRight } from 'lucide-react'
import { Row, Col } from 'react-bootstrap'
import Input from '../../components/forms/Input'
import Recap from "./Recap";
import BookingForm from "./BookingForm";
import Options from "./Options";
function Booking() {

    const { id } = useParams();
    let flight = details.flights.filter(objet => objet.id === parseInt(id))[0];

    return (

        <section>
            <div className='my-5 container'>
                <Row>
                    <Col lg="7" xl="8">
                        <div>

                            <fieldset>
                                <legend className='fw-bold text-dark fs-3'>Booking Submission</legend>                                
                                <BookingForm />
                                <Row>
                                    <Col lg="6" className='my-2'>
                                        <Input label="Passport number" name="passportnb" id="passportnb" type="text" placeholder="Passport number" required={true} />
                                    </Col>
                                    <Col lg="6" className='my-2'>
                                        <Input label="Passport expiration" name="passportexpiration" id="passportexpiration" type="text" placeholder="Expiration date" required={true} />
                                    </Col>
                                </Row>
                                <Options/>
                            </fieldset>
                        </div>
                    </Col>
                    <Col lg="5" xl="4">
                        <Recap name={`${flight.departure_location} - ${flight.departure_destination}`} image={flight.departure_company_img} country={flight.airport_departing} type={flight.class} checkin={flight.date} checkout={flight.date} depart_time={flight.departure_starttime} return_time={flight.departure_endtime} travelers={1} duration={flight.duration} price={98} />
                    </Col>
                </Row>

                <hr className='border-secondary' />

                <div className='my-3 fs-6 d-flex align-items-center'>
                    <input id='terms' type='checkbox' />
                    <label className='mx-2' htmlFor='terms'>I have read and accept the terms and conditions and Privacy Policy</label>
                </div>

                <div className="my-5">
                    <a href={`/booking/cart/${id}/flight`} className='button-blue p-3 px-4 rounded-5 mt-3 fs-6'>
                        Submit <MoveRight />
                    </a>
                </div>
            </div>
        </section>
    );
}

export default Booking;