import { Panel } from 'rsuite';
import { Star } from 'lucide-react'
import FilterMap from '../../components/FilterMap'
import FilterPrice from '../../components/FilterPrice';

const Filters = () => {

    
    const attractions = [
        { name: "Visites touristiques" },
        { name: "Activités" },
        { name: "Musées" },
        { name: "Randonnées" },
    ]

    const category = [
        { stars: 5 },
        { stars: 4 },
        { stars: 3 },
        { stars: 2 },
        { stars: 1 }
    ]

    const duration = [
        { name: "0 - 3 hours" },
        { name: "3 - 5 hours" },
        { name: "5 - 7 hours" },
        { name: "Fullday (+7 hours)" },
        { name: "Multi-day" },
    ]

    const languages = [
        { name: "English" },
        { name: "Chinese" },
        { name: "French" },
        { name: "German" },
        { name: "Italian" },
        { name: "Japanese" },
        { name: "Polish" },
        { name: "Portuguese" },
        { name: "Russian" },
        { name: "Spanish" },
    ]

    const time = [
        { name: "In the morning, 8 AM-12 PM" },
        { name: "In the afternoon, 12 PM-5 PM" },
        { name: "In the evening, 5 PM-12 AM" },
    ]

    const rating = [
        { name: "Wonderful 4.5+" },
        { name: "Very good 4+" },
        { name: "Good 3.5+" },
    ]

    return (
        <>
            <div className='d-none d-xl-block position-relative'>
                <FilterMap/>
            </div>
            <div className="my-3 rounded-4 p-xl-3 bg-white border">
                <FilterPrice/>
            </div>

            <div className="my-3 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Category</span>} collapsible>
                    {category.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center my-1'>
                            <div className='d-flex align-items-center'><input type='checkbox' />
                                <div className='mx-3 fw-light'>
                                    {Array.from({ length: item.stars }).map((_, starIndex) => (
                                        <span className="me-1" key={starIndex}>
                                            <Star color='#F8D448' fill='#F8D448' size={13} />
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Rating</span>} collapsible>
                    {rating.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'><input type='checkbox' /><span className='my-1 mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Attractions</span>} collapsible>
                    {attractions.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center'><input type='checkbox' /><span className='my-1 mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Languages</span>} collapsible>
                    {languages.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center my-1'><input type='checkbox' /><span className='mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Duration</span>} collapsible>
                    {duration.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center my-1'><input type='checkbox' /><span className='mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

            <div className="my-3 rounded-4 p-xl-3 bg-white border">
                <Panel defaultExpanded header={<span className="fw-medium fs-5">Time</span>} collapsible>
                    {time.map((item, index) => (
                        <div key={index} className='d-flex justify-content-between align-items-center' style={{ fontSize: "16px" }}>
                            <div className='d-flex align-items-center my-1'><input type='checkbox' /><span className='mx-3 fw-light'>{item.name}</span></div>
                        </div>
                    ))}
                </Panel>
            </div>

        </>
    )
}

export default Filters