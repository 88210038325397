import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function Services() {

    const { t } = useTranslation();

    const services = [
        { title: t('services_title1'), description: t('services_description1') },
        { title: t('services_title2'), description: t('services_description2') },
        { title: t('services_title3'), description: t('services_description3') },
        { title: t('services_title4'), description: t('services_description4') }
    ]

    const counts = [
        { nb: "22+", title: t('years'), descrption: t('field_experience') },
        { nb: "99%", descrption: t('client_satisfaction') },
        { nb: "3k+", descrption: t('annual_trips') },
        { nb: "2", title: t('mins'), descrption: t('to_book') },
        { nb: "300k+", title: t('destinations'), descrption: t('around_the_world') }
    ]

    return (
        <section className='bg-dark text-white'>
            <div className='container p-5'>
                <Row className='justify-content-between'>
                    <Col xl="7">
                        <div>
                            <span className='text-blue fw-light fs-6'>{t('our_services')}</span>
                            <h2 className='fw-medium h1 my-3'>{t('service_title')}</h2>
                            <p className='fw-light fs-6'>{t('service_text')}</p>
                            <hr className='my-5' />
                            <Row>
                                {services.map((service, index) => (
                                    <Col key={index} md="6">
                                        <div className='p-3 fs-6'>
                                            <span className='fw-medium'>{service.title}</span>
                                            <p className='mt-3 fw-lighter'>{service.description}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Col>
                    <Col xl="3" className='d-none d-md-block'>
                        <Row className='justify-content-around bg-blue-subtle text-dark rounded-3 h-100 ps-4'>
                            {
                                counts.map((count, index) => (
                                    <Col key={index} md="3" lg="2" xl="12" className='fs-5 fw-light my-4'>
                                        <div><span className='fs-1'>{count.nb}</span> {count.title}</div>
                                        <div className='fs-6 text-blue'>{count.descrption}</div>
                                    </Col>
                                ))
                            }
                        </Row>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default Services