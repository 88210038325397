import { Row, Col } from 'react-bootstrap';
import { Users } from 'lucide-react';
import { useTranslation } from 'react-i18next';

function BackgroundSolutions() {

    const { t } = useTranslation();

    return (
        <section className="container">
            <Row className="text-dark d-flex my-5 pt-5">
                <Col xl="6" className='py-5 pt-2 d-flex flex-column align-items-start justify-content-center'>
                    <span className='text-blue'>{t('our_solutions')}</span>
                    <h1 className='fw-medium my-3' dangerouslySetInnerHTML={{__html: t('solutions_title')}}></h1>
                    <p className='fs-5 fw-light my-3' dangerouslySetInnerHTML={{__html: t('solutions_text')}}>
                        
                    </p>
                    <button className='button-blue p-3 px-5 mt-3'>{t('read_more')}</button>
                </Col>
                <Col xl="6" className='py-5 d-none d-xl-block '>
                    <Row>
                        <Col lg="7">
                            <img alt='work team illustration' width="100%" height="100%" src='https://images.pexels.com/photos/3184433/pexels-photo-3184433.jpeg?auto=compress&cs=tinysrgb&w=600' />
                        </Col>
                        <Col lg="5" className='d-flex flex-column'>
                            <img alt='work presentation illustration'  width="100%" height="100%" src='https://images.pexels.com/photos/3184603/pexels-photo-3184603.jpeg?auto=compress&cs=tinysrgb&w=600' />
                            <div className='d-flex align-items-center bg-dark-subtle mt-3 p-3'>
                                <Users strokeWidth={1} size={50} />
                                <div className='mx-3'>
                                    <div><span className='fs-1 fw-bold'>22</span>+</div>
                                    <div>{t('field_experience')}</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>

        </section>
    )
}

export default BackgroundSolutions