import { CalendarDays, CreditCard, HelpingHand } from 'lucide-react';
import React from 'react'
import { Col, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

const Tarification = () => {

    const { t } = useTranslation();

    const tarfisdescription = [
        { title: t('pricing_title1'), description: t('pricing_description1'), icon: <HelpingHand strokeWidth={1.5} size={50} /> },
        { title: t('pricing_title2'), description: t('pricing_description2'), icon: <CreditCard strokeWidth={1.5} size={50} /> },
        { title: t('pricing_title3'), description: t('pricing_description3'), icon: <CalendarDays strokeWidth={1.5} size={50} /> }]


    return (
        <Row className='my-5 py-3'>
            <Col xs="12" xl="6" className='ps-xl-5 pe-xl-5 d-flex flex-column justify-content-around'>

                <div className='text-dark fw-bold fs-2'>{t('pricing')}</div>
                <p className='fs-5 fw-light'>
                    {t('pricing_intro')}
                </p>

                <Row>
                    {tarfisdescription.map((tarif, index) => (
                        <Col xs="12" key={index}>
                            <Row className='my-3'>
                                <Col sm="2" className='d-none d-sm-block p-4 pe-0 text-orange'>
                                    {tarif.icon}
                                </Col>
                                <Col xs="12" sm="10">
                                    <span className='text-dark fs-5 fw-medium'>{tarif.title}</span>
                                    <p className='fs-5 fw-light mt-2' dangerouslySetInnerHTML={{ __html: tarif.description }}></p>
                                </Col>
                            </Row>
                        </Col>
                    ))}
                </Row>
            </Col>
            <Col xs="12" xl="6" className='px-3'>
                <img width="100%" height="650" style={{ objectFit: "cover" }} alt="Un cadre supérieur qui effctue son voyage d'affaire" loading='lazy' src="https://images.pexels.com/photos/5922200/pexels-photo-5922200.jpeg?auto=compress&cs=tinysrgb&w=600" />
            </Col>
        </Row>
    )
}

export default Tarification


