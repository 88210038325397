import { MinusCircle, PlusCircle } from "lucide-react"

const Counter = ({ incrementAdultCount, decrementAdultCount, TotalCount, adultCount, childCount, incrementChildCount, decrementChildCount, infantCount, incrementInfantCount, decrementInfantCount }) => {

  const countsData = [
    { title: "Adults", info: "(Age 16-99)", value: adultCount, increment: incrementAdultCount, decrement: decrementAdultCount, min: 2, max: 4 },
    { title: "Childs", info: "(Age 2-15)", value: childCount, increment: incrementChildCount, decrement: decrementChildCount, min: 1, max: 4 },
    { title: "Infants", info: "(Age 0-1)", value: infantCount, increment: incrementInfantCount, decrement: decrementInfantCount, min: 1, max: 4 },
  ]

  return (
    <div className="p-3 fw-bold">

      {countsData.map((item, index) => (
        <div key={index} className="d-flex justify-content-between py-3 border-bottom">
          <div>
            <div className="text-dark fs-6">{item.title}</div>
            <div className='fw-light'>{item.info}</div>
          </div>

          <div className="d-flex align-items-center">
            <button className="mx-2 bg-white" onClick={item.decrement} disabled={item.value < item.min}><MinusCircle color="#3B71FE" strokeWidth={1.5} /></button>
            <span className="border mx-2 text-dark d-flex align-items-center justify-content-center" style={{ width: "30px", height: "30px" }}>{item.value}</span>
            <button className="mx-2 bg-white" onClick={item.increment} disabled={item.value > item.max}><PlusCircle color="#3B71FE" strokeWidth={1.5} /></button>
          </div>
        </div>
      ))}

    </div>
  )
}

export default Counter