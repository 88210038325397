import { Row, Col } from 'react-bootstrap'
import React, { useState } from 'react';
import { Steps } from 'rsuite';
import { useTranslation } from 'react-i18next';

const Register = () => {

  const { t } = useTranslation();
  const [step, setStep] = useState(1);

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  return (
    <main className='bg-secondary-subtle'>
      <form action='/traitement'>
        <div className='container py-5 d-flex flex-column'>

          <Row>
            <Col lg="12" xl="8" className='p-0'>
              <div className='bg-white py-5 h-100'>
                {step === 1 && (
                  <fieldset className='d-flex flex-column px-1 px-sm-5'>
                    <Steps current={0}>
                      <Steps.Item title={t('in_progress')} description={t('professional')} />
                      <Steps.Item title={t('waiting')} description={t('personnal')} />
                    </Steps>

                    <Row className='mt-5'>
                      <Col md="6">
                        <div>
                          <label htmlFor='entreprise' className='my-2'>{t('entreprise')}</label>
                          <input id='entreprise' className='form-control rounded-0' style={{ boxShadow: "none", height: "1.8cm" }} />
                        </div>
                      </Col>
                      <Col md="6">
                        <div>
                          <label htmlFor='address' className='my-2'>{t('address')}</label>
                          <input id='address' className='form-control rounded-0' style={{ boxShadow: "none", height: "1.8cm" }} />
                        </div>
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col md="6">
                        <div>
                          <label htmlFor='country' className='my-2'>{t('country')}</label>
                          <input id='country' className='form-control rounded-0' style={{ boxShadow: "none", height: "1.8cm" }} />
                        </div>
                      </Col>
                      <Col md="6">
                        <div>
                          <label htmlFor='city' className='my-2'>{t('city')}</label>
                          <input id='city' className='form-control rounded-0' style={{ boxShadow: "none", height: "1.8cm" }} />
                        </div>
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col md="6">
                        <div>
                          <label htmlFor='registre' className='my-2'>{t('commercial_register')}</label>
                          <input id='registre' className='form-control rounded-0' style={{ boxShadow: "none", height: "1.8cm" }} />
                        </div>
                      </Col>
                      <Col md="6">
                        <div>
                          <label htmlFor='nif' className='my-2'>{t('tin')}</label>
                          <input id='nif' className='form-control rounded-0' style={{ boxShadow: "none", height: "1.8cm" }} />
                        </div>
                      </Col>
                    </Row>

                    <Row className='mt-5'>
                      <Col xl="6">
                        <button className='button-blue py-3 px-5 rounded-0' onClick={handleNextStep}>{t('next')}</button>
                      </Col>
                    </Row>
                  </fieldset>
                )}

                {step === 2 && (
                  <fieldset className='d-flex flex-column px-1 px-sm-5'>
                    <Steps current={1}>
                      <Steps.Item title={t('finished')} description={t('professional')} />
                      <Steps.Item title={t('in_progress')} description={t('personnal')} />
                    </Steps>

                    <Row className='mt-5'>
                      <Col md="6">
                        <div>
                          <label htmlFor='nom' className='my-2'>{t('last_name')}</label>
                          <input id='nom' className='form-control rounded-0' style={{ boxShadow: "none", height: "1.8cm" }} />
                        </div>
                      </Col>
                      <Col md="6">
                        <div>
                          <label htmlFor='prenom' className='my-2'>{t('first_name')}</label>
                          <input id='prenom' className='form-control rounded-0' style={{ boxShadow: "none", height: "1.8cm" }} />
                        </div>
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col md="6">
                        <div>
                          <label htmlFor='email' className='my-2'>{t('email')}</label>
                          <input id='email' className='form-control rounded-0' style={{ boxShadow: "none", height: "1.8cm" }} />
                        </div>
                      </Col>
                      <Col md="6">
                        <div>
                          <label htmlFor='tel' className='my-2'>{t('phone')}</label>
                          <input id='tel' className='form-control rounded-0' style={{ boxShadow: "none", height: "1.8cm" }} />
                        </div>
                      </Col>
                    </Row>

                    <Row className='mt-3'>
                      <Col md="6">
                        <div>
                          <label htmlFor='lang' className='my-2'>{t('language')}</label>
                          <select id='lang' className='form-control rounded-0' style={{ boxShadow: "none", height: "1.8cm" }}>
                            <option>Français</option>
                            <option>Anglais</option>
                            <option>Espagnole</option>
                          </select>
                        </div>
                      </Col>
                      <Col md="6">
                        <div>
                          <label htmlFor='usd' className='my-2'>{t('currency')}</label>
                          <select id='usd' className='form-control rounded-0' style={{ boxShadow: "none", height: "1.8cm" }}>
                            <option>DZD</option>
                            <option>USD</option>
                          </select>
                        </div>
                      </Col>
                    </Row>

                    <Row className='mt-5'>
                      <Col md="6">
                        <button className='button-orange py-3 px-5 rounded-0' onClick={handlePrevStep}>{t('previous')}</button>
                      </Col>
                      <Col md="6">
                        <button className='button-blue w-100 py-3 px-5 rounded-0' type='submit'>{t('next')}</button>
                      </Col>
                    </Row>
                  </fieldset>
                )}
              </div>
            </Col>

            <Col xl="4" className='p-0 d-none d-xl-block'>
              <section className='border bg-white text-dark h-100'>
                <div className='d-flex flex-column justify-content-between h-100'>
                  <div className='p-5'>
                    <h1 className="fw-bold">{t('join_us')}</h1>
                    <p className='fs-5 mt-5'>« {t('register_intro')} »</p>
                  </div>

                  <div className='d-flex justify-content-center'>
                    <img width={520} height={297} className='w-100' style={{ maxHeight: "280px" }} src="http://preview.robertbiswas.com/html/topbiznes/images/projects/project-6.jpg" alt="Des cadres d'entreprise signent un contrat" />
                  </div>
                </div>
              </section>
            </Col>

          </Row>
          <div>

          </div>

        </div>
      </form>
    </main>
  )
}

export default Register