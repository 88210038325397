import BackgroundEvents from './BackgroundsEvents'
import EventsItems from './EventsItems'
import EventList from './EventList'

function MainEvents(){ 

    return(

       <main>
            <BackgroundEvents/>
            <EventsItems/>
            <EventList/>
       </main>
        
    )
}

export default MainEvents