import './App.css';
import 'rsuite/dist/rsuite-no-reset.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Session } from "./redux/session";
import Header from './layout/Header'
import Footer from './layout/Footer'
import RouteComponents from './routeComponents'
import { NotificationContainer } from "react-notifications";
import 'rsuite/dist/rsuite.min.css';
import LiveChat from './components/LiveChat'

function App() {
  Session()

  return (
    <div className='main'>
      <Header />
      <RouteComponents />
      <Footer />
      <NotificationContainer />
      <LiveChat />
    </div>
  );
}
export default App;
